import * as Sentry from "@sentry/node";
import axios, { AxiosResponse } from "axios";
import { IConversionType } from "../containers/Converter/types.js";

import { handleError } from "../errors";
import { BACKEND_ENDPOINTS } from "../constants/backend";

export type IResponse = {
  fileName: string;
  preview: string;
  artboardsCount: number;
};

export type IUploadStatusResponse = {
  isDone: boolean;
  isFailed: boolean;
};

const handleUploadFigmaError = (response: AxiosResponse) => {
  if (response.status > 400) {
    throw new Error(response.statusText);
  }
  return response;
};

export const uploadFigmaFile = (
  email: string,
  fileURL: string | null,
  format: IConversionType | null,
): Promise<AxiosResponse<IResponse>> => {
  const data = { email, file: fileURL, format };
  return axios
    .request({
      method: "post",
      url: BACKEND_ENDPOINTS.uploadFigma,
      data,
    })

    .then(handleUploadFigmaError)
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setTag("where", "Services: uploadFile");
        scope.setExtras({
          file: fileURL,
          format,
          errorType: "UPLOAD_SERVICE_ERROR",
        });
        Sentry.captureException(error);
      });
      throw error;
    });
};
