import React from "react";
import { useFela } from "react-fela";
import { ProgressSpinner } from "@/components/ProgressSpinner";
import { useTheme } from "@/hooks/useTheme";
import { calculateSpinnerPerc } from "@/utils/calculateSpinnerPerc";
import { IUploadProgress } from "@/containers/Converter/UploadFile/state/types";
import { IConvertStatus } from "@/containers/Files/types";
import { ProgressSpinnerText } from "./ProgressSpinnerText";
import { IConversionType } from "../Converter/types";

interface IConverting {
  progressData?: IUploadProgress;
  pagesData?: IUploadProgress;
  isConversion?: boolean;
  conversionStatus?: IConvertStatus;
  conversionType?: IConversionType;
  title?: string;
  isDoneProcessing?: boolean;
}

export const ProgressSpinnerScreen: React.FC<IConverting> = ({
  progressData,
  pagesData,
  isConversion,
  conversionStatus,
  conversionType,
  title,
  isDoneProcessing,
}) => {
  const { css } = useFela();
  const { colors } = useTheme();

  const { total, loaded, stage } = progressData || {};
  const { total: totalPages, loaded: loadedPages } = pagesData || {};

  const currentProgress = loaded || loadedPages || 0;
  const totalProgress = total || totalPages || 0;

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 51,
        marginBottom: 23,
      })}
    >
      <ProgressSpinner
        color={colors.ORANGE}
        percentage={calculateSpinnerPerc(
          currentProgress,
          totalProgress,
          conversionStatus,
        )}
      />
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
          fontSize: 16,
          textAlign: "center",
          fontWeight: "bold",
          lineHeight: 1.5,
          nested: {
            "> div:not(:last-child)": {
              marginRight: 20,
            },
          },
        })}
      >
        {!!totalPages && totalPages > 0 && (
          <ProgressSpinnerText
            isConversion={isConversion}
            conversionType={conversionType}
            isDoneProcessing={isDoneProcessing}
            title={stage === 1 ? "Converting page" : null}
            total={totalPages || 0}
            loaded={loadedPages || 0}
          />
        )}
        {!!total && total > 0 && (
          <ProgressSpinnerText
            isConversion={isConversion}
            conversionType={conversionType}
            isDoneProcessing={isDoneProcessing}
            title={stage === 1 ? title : null}
            total={total || 0}
            loaded={loaded || 0}
          />
        )}
      </div>
    </div>
  );
};
