import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Error } from "@/containers/Converter/common/components/Error";
import { Button } from "@/components/Button";

export const FileNotFoundError: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation("converter");
  return (
    <Error
      title={t("fileNotFound.title")}
      description={t("fileNotFound.description")}
      icon="FILE-NOT-FOUND-ICON"
    >
      <Button
        customStyle={{
          margin: "45px auto 0",
          padding: "0 46px",
          width: "auto",
        }}
        onClick={() => router.push("/")}
      >
        {t("fileNotFound.actionButton")}
      </Button>
    </Error>
  );
};
