import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Error } from "@/containers/Converter/common/components/Error";
import { Button } from "@/components/Button";

interface IProps {
  onRetry: () => void;
}

export const UploadError: React.FC<IProps> = props => {
  const { t } = useTranslation("converter");
  const { onRetry } = props;

  return (
    <>
      <Error>
        <Button
          customStyle={{
            margin: "45px auto 0",
            padding: "0 46px",
            width: "auto",
          }}
          onClick={onRetry}
        >
          {t("error.restartUpload")}
        </Button>
      </Error>
    </>
  );
};
