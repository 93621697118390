import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IIconType } from "@/icons";
import { Link } from "../../Link";
import { Icon, StyledIconWrapper } from "../../Icon";

interface IArticleItem {
  icon: IIconType;
  title: string;
  text: string;
  href: string;
}

export const ArticleItem: React.FC<IArticleItem> = ({
  icon,
  title,
  text,
  href,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation("downloadHelpArticleModal");
  return (
    <Link
      href={href}
      target="_blank"
      className={css({
        flexBasis: "33%",
        padding: "24px 32px",
        border: "1px solid #E5EAF4",
        borderRadius: 4,
        textDecoration: "none",
        nested: {
          ":not(:last-child)": {
            marginBottom: 20,
            nested: {
              [bp.FROM_DESKTOP]: {
                margin: "0 20px 0 0",
              },
            },
          },
        },
      })}
    >
      <StyledIconWrapper
        customStyle={{
          width: 24,
          height: 24,
        }}
        wrapperStyle={{
          width: 50,
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 24,
          borderRadius: "50%",
          border: "1px solid #E5EAF4",
        }}
      >
        <Icon iconType={icon} />
      </StyledIconWrapper>
      <p
        className={css({
          marginBottom: 16,
          fontSize: 18,
          lineHeight: "26px",
          letterSpacing: "0.5px",
          fontWeight: "bold",
          color: colors.CHARCOAL,
        })}
      >
        {title}
      </p>
      <p
        className={css({
          marginBottom: 24,
          fontSize: 14,
          lineHeight: "24px",
          color: colors.DARK_GREY,
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        })}
      >
        {text}
      </p>
      <p
        className={css({
          fontSize: 14,
          lineHeight: "24px",
          fontWeight: 500,
          color: colors.PALE_PURLPE,
          textDecoration: "underline",
          nested: {
            "> svg path": {
              stroke: colors.PALE_PURLPE,
            },
          },
        })}
      >
        {t("readMore")}
      </p>
    </Link>
  );
};
