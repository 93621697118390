import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { useRouter } from "next/router";

import { createPreviewAndPaymentMachine } from "@/containers/Converter/PreviewAndPayment/state/machine";
import { Preview } from "@/containers/Converter/PreviewAndPayment/components/Preview";
import { ConfirmCreditDeductionModal } from "@/containers/Converter/PreviewAndPayment/components/ConfirmCreditDeductionModal";
import { Payment } from "@/containers/Converter/PreviewAndPayment/components/Payment";
import { getPnPMachineArgs } from "@/containers/Converter/PreviewAndPayment/state/machineArguments";
import { useAuth } from "@/hooks/useAuth";
import { PaymentSuccessfulPopup } from "@/containers/Converter/PreviewAndPayment/components/SuccessModal";
import {
  betaConversionFormats,
  conversionFormats,
  landingPagesMap,
} from "@/containers/Converter/maps";
import {
  getConversionDetails,
  mergeConversionType,
} from "@/containers/Converter/common/helpers";
import { redirectToFilePage } from "@/containers/Converter/common/helpers/redirectToFilePage";
import { FileNotFoundError } from "@/containers/Converter/PreviewAndPayment/components/FileNotFoundError";
import { LOCAL_STORAGE_KEYS } from "@/constants/localStorage";
import { PreviewAndPaymentProps } from "@/containers/Converter/PreviewAndPayment/types";

export const PreviewAndPayment: React.FC<PreviewAndPaymentProps> = props => {
  const router = useRouter();
  const { user, refreshAccount, loading } = useAuth();

  const machineArgs = getPnPMachineArgs(router, user, props.converterCtx);
  const previewAndPaymentMachine = createPreviewAndPaymentMachine(machineArgs, {
    refreshAccount,
  });

  const [current, send] = useMachine(previewAndPaymentMachine);
  const ctx = current.context;

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.uploadedFileName,
    `${ctx.uploadedFileName}?type=${ctx.conversionType}`,
  );

  const showPreview = !current.matches("error");
  const showConfirmCreditDeductionModal = current.matches(
    "confirmCreditDeduction",
  );
  const showPaymentPlans =
    current.matches("selectPaymentType") || current.matches("checkout");
  const showPaymentSuccessfulPopup = current.matches("paymentSuccessfulPopup");
  const showError = current.matches("error");

  useEffect(() => {
    send({
      type: "UPDATE_USER",
      user: {
        ...user,
        loggedIn: Boolean(user),
        hasFreeCredits: user
          ? user.usedCredits < user.totalCredits || user.totalCredits === -1
          : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);

  useEffect(() => {
    if (current.matches("toConversion")) {
      props.onExit(ctx, "goToConverter");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.value]);

  return (
    <>
      {showPreview && (
        <Preview
          fileDetails={ctx.fileDetails!}
          conversionType={ctx.conversionType}
          formats={conversionFormats.filter(
            format => !betaConversionFormats.includes(format),
          )}
          onTargetConversionTypeChanged={newTarget => {
            send({
              type: "CHANGE_TARGET_CONVERSION",
              conversionTarget: newTarget,
            });
            const { conversionSource } = getConversionDetails(
              ctx.conversionType,
            );
            const newType = mergeConversionType(conversionSource, newTarget);
            redirectToFilePage({
              fileName: ctx.uploadedFileName,
              conversionType: newType,
              newUser: ctx.user.isNew,
              userEmail: ctx.user.email,
            });
          }}
          onConvertBtnClicked={() => {
            send({ type: "CONVERT_FILE" });
          }}
          onResetBtnClicked={() => {
            const landingPage = landingPagesMap[ctx.conversionType];
            router.push(landingPage);
          }}
        />
      )}

      {showConfirmCreditDeductionModal && (
        <ConfirmCreditDeductionModal
          onSubmit={() => send({ type: "CONFIRM_CREDIT_DEDUCTION" })}
          onReject={() => send({ type: "REJECT_CREDIT_DEDUCTION" })}
        />
      )}

      {showPaymentPlans && (
        <Payment
          conversionType={ctx.conversionType}
          artboardsCount={ctx.fileDetails!.artboardsCount}
          onPaymentTypeSelected={paymentType => {
            send({
              type: "SELECT_PAYMENT_TYPE",
              paymentType,
            });
          }}
          afterUserLoginViaAlreadyACustomerBtn={() => {
            send({ type: "BACK_TO_PREVIEW" });
          }}
          onModalWindowClose={() => {
            send({ type: "BACK_TO_PREVIEW" });
          }}
        />
      )}

      {showPaymentSuccessfulPopup && (
        <PaymentSuccessfulPopup
          newUser={ctx.user.isNew}
          onClose={() =>
            send({
              type: "CLOSE_POPUP",
            })
          }
        />
      )}

      {showError && <FileNotFoundError />}
    </>
  );
};
