import * as Sentry from "@sentry/node";

import { IConversionType } from "@/containers/Converter/types";
import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { IConvertStatus } from "@/containers/Files/types";
import { handleError, handleHttpError } from "@/errors";

interface IParams extends Record<string, any> {
  fileName: string;
  free?: boolean;
  hostedPageId?: string;
  convertTo: string;
  cliParams?: string[];
}

interface IPaymentStatusParams extends Record<string, any> {
  fileName: string;
  conversionType?: IConversionType | null;
}

export interface IPaymentStatusResult {
  hostedPageId?: string;
  paymentStatus?: string;
}

export interface IConvertFileResponse {
  originalFileName: string;
  resultFileName: string;
}

export interface IConvertStatusResponse {
  status: IConvertStatus;
  currentArtboard: number;
  currentArtboardName: string;
  totalArtboards: number;
  totalImages: number;
  currentImage: number;
  currentPage?: number;
  totalPages?: number;
  stage?: number;
  totalProgress?: number;
}

const convertFile = (params: IParams): Promise<IConvertFileResponse> => {
  const queryParams = Object.fromEntries(
    // eslint-disable-next-line
    new URLSearchParams(location.search),
  );
  const cliFlagsParam = queryParams?.cliFlags?.split(",");
  return fetch(BACKEND_ENDPOINTS.convertFile, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      ...params,
      ...(cliFlagsParam ? { cliFlags: [...cliFlagsParam] } : null),
    }),
  })
    .then(handleHttpError)
    .then(r => r.json())
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras(params);
        scope.setTag("where", "Services: convertFile");
        Sentry.captureException(error);
      });
      throw error;
    });
};

const convertStatus = async (
  fileName: string,
): Promise<IConvertStatusResponse> => {
  const statuses = await convertStatuses([fileName]);
  return statuses[0];
};

const convertStatuses = async (
  fileNames: string[],
): Promise<IConvertStatusResponse[]> => {
  const parsedFilesList = fileNames.map(
    (name: string) => `fileName=${encodeURIComponent(name)}`,
  );
  const url = `${BACKEND_ENDPOINTS.convertStatus}?${parsedFilesList.join("&")}`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then(r => r.json())
    .then(r => {
      const statusesMap = r as { [fileName: string]: IConvertStatusResponse };
      const statusesInOriginalOrder: IConvertStatusResponse[] = [];
      for (const [fileNameKey, fileStatus] of Object.entries(statusesMap)) {
        const i = fileNames.findIndex(it => it === fileNameKey);
        statusesInOriginalOrder[i] = fileStatus;
      }
      return statusesInOriginalOrder;
    })
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({ fileNames });
        scope.setTag("where", "Services: paymentStatus");
        Sentry.captureException(error);
      });
      throw error;
    });
};

const paymentStatus = async ({
  fileName,
  conversionType,
}: IPaymentStatusParams): Promise<IPaymentStatusResult> => {
  const convertType = conversionType as string;
  const url = `${BACKEND_ENDPOINTS.paymentStatus}?fileName=${encodeURIComponent(
    fileName,
  )}&conversionType=${encodeURIComponent(convertType)}`;

  return fetch(url, {
    method: "GET",
  })
    .then(r => r.json())
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({ fileName });
        scope.setTag("where", "Services: paymentStatus");
        Sentry.captureException(error);
      });
      throw error;
    });
};

interface IPayWithCreditsParams extends Record<string, any> {
  fileName: string;
  conversionType?: IConversionType | null;
}

const payWithCredits = (
  conversionData: IPayWithCreditsParams,
): Promise<{ message: string }> => {
  const url = BACKEND_ENDPOINTS.payWithCredits;

  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(conversionData),
  })
    .then(r => r.json())
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras(conversionData);
        scope.setTag("where", "Services: payWithCredits");
        Sentry.captureException(error);
      });
      throw error;
    });
};

export { convertFile, convertStatus, paymentStatus, payWithCredits };
