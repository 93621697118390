import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "@/components/Heading";
import { Spinner } from "@/components/Spinner";
import { ProgressSpinnerScreen } from "@/containers/ProgressSpinnerScreen";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { IUploadProgress } from "@/containers/Converter/UploadFile/state/types";
import { IConversionType } from "@/containers/Converter/types";
import { useTheme } from "@/hooks/useTheme";

interface IProps {
  conversionType: IConversionType;
  uploadProgress?: IUploadProgress;
}

export const Uploading: React.FC<IProps> = props => {
  const { css } = useFela();
  const { t } = useTranslation("converter");
  const { colors } = useTheme();

  const { conversionType, uploadProgress } = props;
  const { conversionSource } = getConversionDetails(
    conversionType || "FIGMA_TO_XD",
  );

  const downloadingText =
    conversionSource === "figma"
      ? t("uploading.downloadingAssets")
      : t("uploading.uploadingFile");

  if (
    uploadProgress &&
    uploadProgress.total > 0 &&
    uploadProgress?.total === uploadProgress?.loaded
  ) {
    return (
      <div
        className={css({
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Heading as="h4">{t("uploading.generatingPreview")}</Heading>
        <div className={css({ height: 200 })}>
          <Spinner />
        </div>
        <p
          className={css({
            color: "#B3B8C2",
            fontSize: 14,
            fontWeight: "normal",
            marginBottom: 12,
          })}
        >
          {t("uploading.hangInThere")}
        </p>
        <strong>{t("uploading.generatingPreviewDescription")}</strong>
      </div>
    );
  }
  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
    >
      <Heading as="h4">{downloadingText}</Heading>

      <ProgressSpinnerScreen
        progressData={uploadProgress}
        conversionType={conversionType}
      />

      <p
        className={css({
          fontSize: 14,
          fontWeight: 600,
          textAlign: "center",
          maxWidth: 240,
          lineHeight: 1.7,
          color: colors.MAASTRICHT_BLUE,
        })}
      >
        <strong>{t("uploading.uploadingFileDescription")}</strong>
      </p>
    </div>
  );
};
