import { NextRouter } from "next/router";
import { isString } from "@/utils/isString";
import { IUser } from "@/hooks/useAuth/types";
import {
  IConversionType,
  IConverterContext,
} from "@/containers/Converter/types";
import { IContextUserData } from "./types";

export type IPreviewAndPaymentMachineArgs = {
  conversionType: IConversionType;
  user: IContextUserData;
  uploadedFileName: string;
};

export function getPnPMachineArgs(
  router: NextRouter,
  user: IUser | null,
  converterCtx: IConverterContext,
): IPreviewAndPaymentMachineArgs {
  const {
    query: { fileName, userEmail, newUser: newUserStr },
  } = router;

  const hasFreeCredits = user
    ? user.usedCredits < user.totalCredits || user.totalCredits === -1
    : false;

  const uploadedFileName =
    isString(fileName) && fileName.length > 0 ? fileName : null;
  if (!uploadedFileName) {
    throw Error("Invalid state: fileName query argument is null");
  }

  const userProvidedEmail = userEmail as string | undefined;
  const isNewUser = newUserStr !== "false";

  const email = user?.email || userProvidedEmail;

  return {
    conversionType: converterCtx.conversionType,
    uploadedFileName,
    user: {
      hasFreeCredits,
      email,
      isNew: isNewUser,
      loggedIn: Boolean(user),
      latestPlanType: user?.latestPlanType,
    },
  };
}
