import React from "react";
import { useFela } from "react-fela";

import { Select } from "@/components/Select";
import { useTheme, IWithCustomStyle } from "@/hooks/useTheme";
import { Icon } from "@/components/Icon";
import {
  getConversionDetails,
  getConversionFormats,
  mergeConversionType,
} from "@/containers/Converter/common/helpers";
import {
  betaConversionFormats,
  extensionNamesMap,
  formatToIconMap,
  landingPagesMap,
} from "@/containers/Converter/maps";
import {
  IConversionTarget,
  IConversionType,
} from "@/containers/Converter/types";
import { useRouter } from "next/router";

export interface IProps extends IWithCustomStyle {
  formats: IConversionType[];
  conversionType?: IConversionType;
  disabled?: boolean;
  disabledSource?: boolean;
  onConversionTypeChange?: (newType: IConversionType) => void;
  allowBeta?: boolean;
}

export const ConversionFormatSelect: React.FC<IProps> = props => {
  const { css } = useFela();
  const { bp } = useTheme();
  const router = useRouter();
  const {
    conversionType,
    formats,
    disabled,
    disabledSource,
    customStyle,
    onConversionTypeChange,
    allowBeta = true,
  } = props;

  const [routesFetched, setRoutesFetched] = React.useState<boolean>(false);

  /**
   * Get current formats
   */
  const {
    conversionSource: selectedSourceFormat,
    conversionTarget: selectedTargetFormat,
  } = getConversionDetails(conversionType || "XD_TO_SKETCH");

  /**
   * Transform conversion types to formats map
   */
  const availableFormats = React.useMemo(() => getConversionFormats(formats), [
    formats,
  ]);

  /**
   * Get available formats
   */
  const sourceFormats = React.useMemo(
    () =>
      (Object.keys(availableFormats) as IConversionTarget[]).map(format => ({
        value: format,
        label: extensionNamesMap[format],
        icon: formatToIconMap[format],
        id: `source-${format}`,
      })),
    [availableFormats],
  );

  const getBetaTargetFormat = (sourceFormat: IConversionTarget) => {
    return betaConversionFormats.reduce(
      (acc: string[], cur: IConversionType) => {
        if (sourceFormat === cur.split("_")[0].toLowerCase()) {
          acc.push(cur.split("_")[2].toLowerCase());
        }

        return acc;
      },
      [],
    );
  };

  const targetFormats = React.useMemo(() => {
    const formats = availableFormats[selectedSourceFormat] || [];

    let finalTargetFormats = formats;

    if (!allowBeta) {
      const betaSources = getBetaTargetFormat(selectedSourceFormat);
      finalTargetFormats = formats.filter(item => !betaSources.includes(item));
    }

    return finalTargetFormats.map(format => ({
      value: format,
      label: extensionNamesMap[format],
      icon: formatToIconMap[format],
      id: `target-${format}`,
    }));
  }, [availableFormats, selectedSourceFormat, allowBeta]);

  const handleRoutePrefetching = () => {
    if (routesFetched) return;

    Object.entries(landingPagesMap).forEach(([, value]) =>
      router.prefetch(value),
    );

    setRoutesFetched(true);
  };

  const handleOptionChange = (
    format: "source" | "target",
    value: IConversionTarget,
  ) => {
    if (!onConversionTypeChange) {
      return;
    }

    let nextSourceFormat = selectedSourceFormat;
    let nextTargetFormat = selectedTargetFormat;

    if (format === "source") {
      nextSourceFormat = value;
      nextTargetFormat = availableFormats[nextSourceFormat]![0];
    } else {
      nextTargetFormat = value;
    }

    const conversionType = mergeConversionType(
      nextSourceFormat,
      nextTargetFormat,
    );
    onConversionTypeChange(conversionType);
  };

  return (
    <div
      role="list"
      onClick={handleRoutePrefetching}
      className={css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        flexDirection: "column",
        nested: {
          "> svg": {
            margin: "20px auto",
            transform: "rotate(90deg)",
          },
        },
        [bp.FROM_TABLET]: {
          flexDirection: "row",
          alignItems: "center",
          nested: {
            "> div": {
              flexBasis: "44%",
            },
            "> svg": {
              margin: 0,
              transform: "none",
            },
          },
        },
        ...customStyle,
      })}
    >
      <Select
        disabled={(disabled || disabledSource) ?? false}
        value={selectedSourceFormat}
        options={sourceFormats}
        onOptionSelect={value =>
          handleOptionChange("source", value as IConversionTarget)
        }
        testId="converter-format-select--source"
      />

      <Icon iconType="ARROW-RIGHT" />

      <Select
        disabled={disabled ?? false}
        value={selectedTargetFormat}
        options={targetFormats}
        onOptionSelect={value =>
          handleOptionChange("target", value as IConversionTarget)
        }
        testId="converter-format-select--target"
      />
    </div>
  );
};
