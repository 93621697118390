const canvaFileUrl = "canva.com/design";

const canvaFileIdRegex = /(design)\/([^/]+)/;

export const getCanvaFileId = (value: string) => {
  const fileSubstr = canvaFileIdRegex.exec(value);
  return (fileSubstr && fileSubstr[2]) || null;
};

export const validateCanvaURL = (value: string) => {
  const isCanvaUrl = value.toLowerCase().includes(canvaFileUrl);
  const hasFileId = getCanvaFileId(value);
  return isCanvaUrl && hasFileId;
};
