import React from "react";
import { createConvertAndViewMachine } from "@/containers/Converter/ConvertAndView/state/machine";
import { useMachine } from "@xstate/react";
import { Converting } from "@/containers/Converter/ConvertAndView/components/Converting";
import { IConverterContext } from "@/containers/Converter/types";
import { getMachineArgs } from "@/containers/Converter/ConvertAndView/state/context";
import { Preview } from "@/containers/Converter/ConvertAndView/components/Preview";
import { redirectToFilePage } from "@/containers/Converter/common/helpers/redirectToFilePage";
import { useRouter } from "next/router";
import { ConversionError } from "@/containers/Converter/ConvertAndView/components/ConversionError";

interface IProps {
  converterCtx: IConverterContext;
}

export const ConvertAndView: React.FC<IProps> = ({ converterCtx }) => {
  const args = getMachineArgs(converterCtx);
  const convertAndViewMachine = createConvertAndViewMachine(args);

  const [current, send] = useMachine(convertAndViewMachine);
  const router = useRouter();
  const { context: ctx } = current;

  const showConverting =
    current.matches("startConversion") ||
    current.matches("pollingConversionStatus") ||
    current.matches("fetchingFile");
  const showFilePreview = current.matches("filePreview");
  const showError = current.matches("error");

  return (
    <>
      {showConverting && <Converting conversionData={ctx.conversionStatus} />}

      {showFilePreview && (
        <Preview
          uploadedFileDetails={ctx.fileDetails}
          conversionType={ctx.conversionType}
          onConvertAllArtboardsBtn={() =>
            redirectToFilePage({
              conversionType: ctx.conversionType,
              fileName: ctx.uploadedFileName,
              userEmail: converterCtx.userEmail || undefined,
              newUser: true,
            })
          }
          onConvertAnotherBtn={() => router.push("/")}
        />
      )}

      {showError && (
        <ConversionError
          retryConversion={() => {
            send({
              type: "RETRY_CONVERSION",
            });
          }}
        />
      )}
    </>
  );
};
