import { IContext } from "./types";

export const initialContext: IContext = {
  conversionType: null,
  file: null,
  fileURL: null,
  newUser: true,
  numberOfArtboards: null,
  uploadProgress: {
    loaded: 0,
    stage: 0,
    total: 0,
  },
  uploadedFileName: null,
  userEmail: null,
  errorType: null,
};
