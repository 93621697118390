import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Button } from "@/components/Button";
import { useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";
import { Icon, StyledIconWrapper } from "@/components/Icon";

interface IProps {
  onRetry: () => void;
}

export const XdError: React.FC<IProps> = ({ onRetry }) => {
  const { t } = useTranslation("converter");
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [bp.FROM_TABLET]: {
          padding: "23px 50px",
        },
      })}
    >
      <StyledIconWrapper
        wrapperStyle={{ position: "relative" }}
        customStyle={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <span
          className={css({
            display: "block",
            width: 88,
            height: 88,
            background: colors.RED,
            opacity: 0.1,
            borderRadius: "50%",
          })}
        />
        <Icon iconType="WARNING" />
      </StyledIconWrapper>
      <Heading
        as="h3"
        customStyle={{
          fontSize: "28px !important",
          textAlign: "center",
          margin: "24px 0 16px",
        }}
      >
        {t("xdCloudFileError.title")}
      </Heading>

      <p
        className={css({
          fontWeight: 500,
          color: colors.DARK_GREY,
          textAlign: "center",
        })}
      >
        {t("xdCloudFileError.description")}{" "}
        <a
          className={css({
            color: colors.DARK_GREY,
            fontWeight: 600,
            textDecoration: "underline",
          })}
          href={t("xdCloudFileError.helpLink")}
          target="_blank"
          rel="noreferrer"
        >
          See here
        </a>
      </p>

      <Button
        customStyle={{
          margin: "45px auto 0",
          padding: "0 46px",
          width: "auto",
        }}
        onClick={onRetry}
      >
        {t("xdCloudFileError.actionButton")}
      </Button>
    </div>
  );
};
