import React, { useEffect, useState } from "react";
import {
  IConversionTarget,
  IConversionType,
} from "@/containers/Converter/types";
import { DroppedFile } from "@/components/DroppedFile";
import { Input } from "@/components/Input";
import { IconMessage } from "@/components/IconMessage";
import { Button } from "@/components/Button";
import { useAuth } from "@/hooks/useAuth";
import { useTheme } from "@/hooks/useTheme";
import useTranslation from "next-translate/useTranslation";
import { validateEmail } from "@/utils/validateEmail";
import { ConversionFormatSelect } from "@/containers/Converter/common/components/ConversionFormatSelect";
import { getConversionFormats } from "@/containers/Converter/common/helpers/getConversionFormats";
import {
  extensionMap,
  getConversionDetails,
  mergeConversionType,
} from "@/containers/Converter/common/helpers";
import { betaConversionFormats } from "@/containers/Converter/maps";

interface IProps {
  file: File;
  conversionType: IConversionType;
  formats: IConversionType[];
  onFileSubmit: (userEmail: string) => void;
  onFileReset: () => void;
  onConversionTypeChange: (newType: IConversionType) => void;
  updateConversionType: (newType: IConversionType) => void;
}

export const ConverterDroppedFile: React.FC<IProps> = props => {
  const [email, setEmail] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  const { loggedIn, loading } = useAuth();
  const { bp } = useTheme();
  const { t } = useTranslation("converter");

  const {
    file,
    onFileSubmit,
    onFileReset,
    conversionType,
    formats,
    onConversionTypeChange,
    updateConversionType,
  } = props;

  const [updatedConv, setUpdatedConv] = useState(conversionType);

  const availableFormats = React.useMemo(
    () =>
      getConversionFormats(
        formats.filter(format => !betaConversionFormats.includes(format)),
      ),
    [formats],
  );

  const { conversionTarget } = getConversionDetails(conversionType);

  const updateFormatSelectOption = (value: IConversionTarget) => {
    const conversionTargetFormat = availableFormats[value]?.includes(
      conversionTarget,
    )
      ? conversionTarget
      : availableFormats[value]![0];
    const conversionType = mergeConversionType(value, conversionTargetFormat);

    setUpdatedConv(conversionType);
    updateConversionType(conversionType);
  };

  useEffect(() => {
    const { name } = file;
    const extension = name.split(".")[name.split(".").length - 1].toLowerCase();
    const sourceType = Object.keys(extensionMap).find(
      key => extensionMap[key] === `.${extension}`,
    );

    updateFormatSelectOption(sourceType as IConversionTarget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUpdatedConv(conversionType);
  }, [conversionType]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.target.value);
    setIsError(false);
  };

  return (
    <DroppedFile
      file={file}
      onFileSubmit={() => {
        if (!loggedIn && !loading) {
          const isValidEmail = validateEmail(email);

          if (!isValidEmail) {
            setIsError(true);
            return;
          }
        }

        onFileSubmit(email);
      }}
      onFileReset={onFileReset}
    >
      <ConversionFormatSelect
        customStyle={{ marginTop: 28 }}
        conversionType={updatedConv}
        disabled={false}
        formats={formats}
        onConversionTypeChange={onConversionTypeChange}
        allowBeta={false}
      />

      {!loggedIn && !loading && (
        <Input
          required
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder={t("selectingFile.enterYourEmail")}
          customStyle={{ marginTop: 28 }}
        />
      )}

      {isError && <IconMessage message={t("selectingFile.enterValidEmail")} />}

      <Button
        type="submit"
        customStyle={{
          marginTop: 24,
          maxWidth: 185,
          alignSelf: "center",

          [bp.FROM_TABLET]: {
            marginTop: `${loggedIn ? 60 : 24}`,
          },
        }}
        title={t("selectingFile.uploadNow")}
      >
        {t("selectingFile.uploadNow")}
      </Button>
    </DroppedFile>
  );
};
