import React from "react";
import { useFela } from "react-fela";

export const Placeholder: React.FC = () => {
  const { css } = useFela();

  return (
    <div
      className={css({
        minHeight: 450,
        display: "flex",
        alignItems: "center",
      })}
    />
  );
};
