import * as Sentry from "@sentry/node";
import FormData from "form-data";
import axios, { AxiosResponse } from "axios";

import { IConversionType } from "@/containers/Converter/types.js";
import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { handleError } from "@/errors";

export type IFileUploadResponse = {
  fileName: string;
  preview: string;
  artboardsCount: number;
};

export type IUploadStatusResponse = {
  isDone: boolean;
  isFailed: boolean;
};

export const uploadFile = (
  email: string,
  file: File,
  format: IConversionType | null,
  onUploadProgress?: (event: ProgressEvent) => void,
): Promise<AxiosResponse<IFileUploadResponse>> => {
  const formData = new FormData();

  formData.append("email", email);
  formData.append(
    "file",
    process.env.NODE_ENV === "test" ? JSON.stringify(file) : file,
  );
  formData.append("format", format);

  return axios
    .request({
      method: "post",
      url: BACKEND_ENDPOINTS.upload,
      data: formData,
      onUploadProgress,
    })
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setTag("where", "Services: uploadFile");
        scope.setExtras({
          fileName: file.name,
          fileSize: file.size,
          format,
          errorType: "UPLOAD_SERVICE_ERROR",
        });
        Sentry.captureException(error);
      });
      throw error;
    });
};

export const fetchUploadStatus = (
  fileName: string,
): Promise<IUploadStatusResponse> => {
  const url = `${BACKEND_ENDPOINTS.uploadStatus}?fileName=${encodeURIComponent(
    fileName,
  )}`;

  return axios
    .get(url)
    .then(r => {
      return r.data;
    })
    .then(handleError)
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setExtras({ fileName });
        scope.setTag("where", "Services: fetchUploadStatus");
        Sentry.captureException(error);
      });
      throw error;
    });
};
