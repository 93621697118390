import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { Button } from "@/components/Button";

type IProps = {
  title: string;
  onClick: () => void;
} & IWithCustomStyle;

export const ConvertAnother: React.FC<IProps> = ({
  title,
  onClick,
  customStyle = {},
}) => {
  const { css } = useFela();

  return (
    <>
      <span
        className={css({
          width: 320,
          marginTop: 35,
          margin: "0 auto",
          borderBottom: "1px solid #F5F5F7",
        })}
      />

      <Button
        title={title}
        onClick={onClick}
        customStyle={{
          margin: "0 auto",
          marginTop: 35,
          maxWidth: 250,
          ...customStyle,
        }}
      >
        {title}
      </Button>
    </>
  );
};
