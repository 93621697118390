import React from "react";
import { useFela } from "react-fela";
import { IUploadProgress } from "@/containers/Converter/UploadFile/state/types";
import { getFormattedFileSize } from "@/utils/getFormattedFileSize";
import { UploadText } from "./UploadText";
import { IConversionType } from "../Converter/types";

interface IConverting {
  isConversion?: boolean;
  conversionStatus?: string;
  conversionType?: IConversionType | null;
  title?: string | null;
  isDoneProcessing?: boolean;
}

type IProps = IUploadProgress & IConverting;
export const ProgressSpinnerText: React.FC<IProps> = ({
  isConversion,
  conversionType,
  title,
  total,
  loaded,
  isDoneProcessing,
}) => {
  const { css } = useFela();

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
    >
      <p
        className={css({
          color: "#B3B8C2",
          fontSize: 14,
          fontWeight: "normal",
        })}
      >
        {isDoneProcessing ? (isConversion ? title : "Uploaded") : null}
      </p>
      {conversionType !== "FIGMA_TO_SKETCH" && isDoneProcessing && (
        <div>
          {isConversion && total ? (
            <UploadText currentValue={loaded || 1} totalValue={total} />
          ) : !isConversion && total ? (
            <UploadText
              currentValue={getFormattedFileSize(loaded)}
              totalValue={getFormattedFileSize(total)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
