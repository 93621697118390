export const addSearchParamsToUrlPath = (
  urlPath: string,
  searchParams: object,
): string => {
  const dummyBaseUrl = "http://dummy";
  const url = new URL(urlPath, dummyBaseUrl);
  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString().replace(dummyBaseUrl, "");
};
