import {
  IRawFileRecord,
  IFileFormat,
  IFileDetailsRecord,
  IRawFileDetails,
} from "@/typings/file";

/**
 * Transforms loaded `IRawFileDetails` to proper `IFileDetails`
 */
export const getFileDetails = (
  fileRecord: IRawFileRecord,
): IFileDetailsRecord => {
  // set formats
  const files = Object.entries<IRawFileDetails>(fileRecord.files).map(
    ([key, value]) => ({
      ...value,
      format: key as IFileFormat,
    }),
  );

  // find source
  const source = files.find(f => f?.isMaster);

  if (!source) {
    throw new Error("File record doesn't have information about source file");
  }

  // find target
  const targets = files.filter(f => !f?.isMaster);

  if (targets.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      "File record contains more that 1 target. Using only first one.",
    );
  }

  const target = targets[0] || null;

  if (!target) {
    source.status = "Uploaded";
  }

  return {
    source,
    target,
  };
};
