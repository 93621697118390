import React from "react";
import { useFela } from "react-fela";

import { StyledIconWrapper, Icon } from "@/components/Icon";

import { useTheme } from "@/hooks/useTheme";
import { IOption } from "./types";

type IProps = IOption & { onClick: () => void };

export const Option: React.FC<IProps> = React.memo(
  ({ label, icon, onClick, id, customStyle }) => {
    const { css } = useFela();
    const { colors } = useTheme();

    return (
      <li
        data-testid={id}
        onClick={onClick}
        className={css({
          padding: 11,
          display: "flex",
          alignItems: "center",
          position: "relative",
          nested: {
            "> *": {
              marginRight: 10,
            },
            ":not(:last-child)": {
              borderBottom: `1px solid ${colors.LIGHT_GRAY3}`,
            },
          },
          minHeight: 50,
          cursor: "pointer",
          ...customStyle,
        })}
      >
        {icon ? (
          <StyledIconWrapper
            wrapperStyle={{ height: 29 }}
            customStyle={{ height: 29, width: 24 }}
          >
            <Icon iconType={icon} />
          </StyledIconWrapper>
        ) : null}
        {label}
      </li>
    );
  },
);
