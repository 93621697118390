import { Machine } from "xstate";
import { IPreviewAndPaymentMachineArgs } from "@/containers/Converter/PreviewAndPayment/state/machineArguments";
import { IContext, IEvent } from "./types";
import { getInitialContext } from "./context";
import {
  setConversionType,
  setFileDetails,
  setFileFetchingError,
  setHostedPageId,
  setIsNewUser,
  setPaymentType,
  setUserData,
} from "./actions";
import {
  notPAYGPayment,
  userLoggedIn,
  userHasFreeCredits,
  onLifetimeSub,
  fileStatusIsOneOf,
  isFreeConversionCheck,
} from "./guards";
import {
  deductCreditsService,
  fetchFileService,
  handleCheckoutService,
} from "./services";

export const createPreviewAndPaymentMachine = (
  args: IPreviewAndPaymentMachineArgs,
  hooks: {
    refreshAccount: () => void;
  },
) =>
  Machine<IContext, any, IEvent>({
    id: "previewAndPayment",
    initial: "fetching",
    context: getInitialContext(args),
    states: {
      fetching: {
        invoke: {
          src: fetchFileService,
          onDone: [
            {
              cond: fileStatusIsOneOf(args.conversionType, [
                "InProgress",
                "Done",
                "Failed",
              ]),
              actions: setFileDetails,
              target: "toConversion",
            },
            {
              actions: setFileDetails,
              target: "preview",
            },
          ],
          onError: {
            actions: setFileFetchingError,
            target: "error",
          },
        },
        on: {
          UPDATE_USER: {
            actions: setUserData,
          },
        },
      },
      preview: {
        on: {
          UPDATE_USER: {
            actions: setUserData,
          },
          CONVERT_FILE: [
            {
              cond: onLifetimeSub,
              target: "deductCredits",
            },
            {
              cond: userHasFreeCredits,
              target: "confirmCreditDeduction",
            },
            {
              cond: isFreeConversionCheck,
              target: "toConversion",
            },
            {
              target: "selectPaymentType",
            },
          ],
          CHANGE_TARGET_CONVERSION: {
            actions: setConversionType,
          },
        },
      },
      confirmCreditDeduction: {
        on: {
          CONFIRM_CREDIT_DEDUCTION: "deductCredits",
          REJECT_CREDIT_DEDUCTION: "preview",
        },
      },
      deductCredits: {
        invoke: {
          src: deductCreditsService,
          onDone: {
            actions: hooks.refreshAccount,
            target: "toConversion",
          },
          onError: "error",
        },
      },
      selectPaymentType: {
        on: {
          SELECT_PAYMENT_TYPE: {
            actions: setPaymentType,
            target: "checkout",
          },
          BACK_TO_PREVIEW: "preview",
        },
      },
      checkout: {
        invoke: {
          src: handleCheckoutService,
        },
        on: {
          COMPLETE_CHECKOUT: [
            {
              cond: ctx => !userLoggedIn(ctx) && notPAYGPayment(ctx),
              actions: [setHostedPageId, setIsNewUser, hooks.refreshAccount],
              target: "paymentSuccessfulPopup",
            },
            {
              actions: [setHostedPageId, hooks.refreshAccount],
              target: "toConversion",
            },
          ],
          BACK_TO_PREVIEW: "preview",
          CLOSE_CHECKOUT: "selectPaymentType",
        },
      },
      paymentSuccessfulPopup: {
        on: {
          CLOSE_POPUP: "toConversion",
        },
      },
      toConversion: {
        type: "final",
      },
      error: {
        // entry: logError,
        type: "final",
      },
    },
  });
