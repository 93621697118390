import React from "react";
import useTranslation from "next-translate/useTranslation";

import { Card } from "@/components/Card";
import { useTheme } from "@/hooks/useTheme";
import { useAuth } from "@/hooks/useAuth";

import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { formatToIconMap } from "@/containers/Converter/maps";
import {
  IConversionTarget,
  IConversionType,
} from "@/containers/Converter/types";

import { IFilesDetails } from "@/containers/Converter/common/types";
import { ConversionFormatSelect } from "@/containers/Converter/common/components/ConversionFormatSelect";
import { FilePreview } from "@/components/FilePreview";
import { isFreeConversion } from "@/utils/converter/isFreeConversion";
import { ConversionPlan } from "./ConversionPlan";
import { Placeholder } from "./Placeholder";
import { HasMissingLinkedElements } from "./HasMissingLinkedElements";

interface IProps {
  fileDetails: IFilesDetails;
  formats: IConversionType[];
  conversionType: IConversionType;
  onConvertBtnClicked: () => void;
  onResetBtnClicked: () => void;
  onTargetConversionTypeChanged: (newType: IConversionTarget) => void;
}

export const Preview: React.FC<IProps> = props => {
  const { t } = useTranslation("converter");
  const { bp } = useTheme();
  const { user } = useAuth();

  const availableCredits =
    (user && user?.totalCredits - user?.usedCredits) || 0;

  const {
    fileDetails,
    formats,
    conversionType,
    onConvertBtnClicked,
    onResetBtnClicked,
    onTargetConversionTypeChanged,
  } = props;

  if (!fileDetails || !conversionType) {
    return <Placeholder />;
  }

  const { conversionSource, conversionTarget } = getConversionDetails(
    conversionType,
  );

  const sourceFile = fileDetails[conversionSource]?.full;
  const fileIconType = formatToIconMap[conversionTarget];

  return (
    <FilePreview
      conversionType={conversionType}
      file={sourceFile ?? undefined}
      previewUrl={fileDetails.previewUrl}
      onResetBtnClicked={onResetBtnClicked}
      fileDetailsCustomStyle={{
        marginTop: 38,
        marginLeft: 15,
        marginRight: 15,
      }}
    >
      <ConversionFormatSelect
        conversionType={conversionType}
        formats={formats}
        disabledSource={true}
        onConversionTypeChange={newType => {
          const { conversionTarget } = getConversionDetails(newType);
          onTargetConversionTypeChanged(conversionTarget);
        }}
        customStyle={{ marginTop: 28 }}
      />
      {conversionSource === "indd" && <HasMissingLinkedElements />}
      <Card
        customStyle={{
          marginTop: 28,
          boxShadow: "0 2px 22px 0 rgba(0, 0, 0, 0.08)",
          [bp.FROM_TABLET]: {
            padding: "28px 30px 28px 30px",
          },
          nested: {
            "> div:not(:first-of-type)": {
              marginTop: 20,
            },
          },
        }}
      >
        <ConversionPlan
          badgeText=""
          fileIconType={fileIconType}
          badgeIconType="STAR-4"
          promotionText={t("preview.allArtboards", {
            count:
              fileDetails.artboardsCount > 1 ? fileDetails.artboardsCount : "",
          })}
          action={{
            event: onConvertBtnClicked,
            title:
              availableCredits > 0 ||
              user?.totalCredits === -1 ||
              isFreeConversion(conversionType)
                ? t("preview.convert")
                : t("preview.payAndConvert"),
            testId: "converter-preview-convert-btn",
          }}
        />
      </Card>
    </FilePreview>
  );
};
