export const calculateSpinnerPerc = (
  loaded: number,
  total: number,
  status?: string,
) => {
  const result = Math.floor((loaded / total) * 100);
  if (status === "InProgress" && total && loaded === total) {
    return result - 1;
  }
  return Math.min(result, 100);
};
