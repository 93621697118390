import React from "react";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";

import { Button } from "@/components/Button";
import { useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";
import { Icon, StyledIconWrapper } from "@/components/Icon";

import { WaitlistDropdown } from "@/containers/PluginWaitlist/WaitlistDropdown";

interface IProps {
  onRetry: () => void;
}

export const FigjamError: React.FC<IProps> = ({ onRetry }) => {
  const { t } = useTranslation("converter");
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [bp.FROM_TABLET]: {
          padding: "23px 50px",
        },
      })}
    >
      <StyledIconWrapper
        wrapperStyle={{ position: "relative" }}
        customStyle={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <span
          className={css({
            display: "block",
            width: 88,
            height: 88,
            background: colors.RED,
            opacity: 0.1,
            borderRadius: "50%",
          })}
        />
        <Icon iconType="WARNING" />
      </StyledIconWrapper>
      <Heading
        as="h3"
        customStyle={{
          fontSize: "28px !important",
          textAlign: "center",
          margin: "24px 0 16px",
        }}
      >
        {t("figjamError.title")}
      </Heading>

      <p
        className={css({
          fontWeight: 500,
          color: colors.DARK_GREY,
          textAlign: "center",
        })}
      >
        {t("figjamError.description")}
      </p>
      <p
        className={css({
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          marginTop: 16,
          fontWeight: 700,
          letterSpacing: 0.5,
          color: colors.CHARCOAL,
          textAlign: "center",
          nested: {
            "> div": {
              marginLeft: 5,
              position: "relative",
            },
          },
        })}
      >
        <Trans
          i18nKey="converter:figjamError.waitlistMessage"
          components={[
            <WaitlistDropdown
              key={0}
              betaType="FIGJAM"
              dropdownTitleStyles={{
                fontSize: 16,
                textDecoration: "none",
                color: colors.ORANGE,
                [bp.FROM_TABLET]: {
                  flexShrink: 0,
                },
              }}
              dropdownContentStyles={{
                [bp.ONLY_MOBILE]: {
                  top: 55,
                  left: -270,
                },
                [bp.FROM_TABLET]: {
                  top: 30,
                  left: -240,
                },
              }}
              betaFormStyles={{
                nested: {
                  "> span": {
                    right: 25,
                    left: "auto",
                  },
                },
              }}
            />,
          ]}
        />
      </p>

      <Button
        customStyle={{
          margin: "45px auto 0",
          padding: "0 46px",
          width: "auto",
        }}
        onClick={onRetry}
        // onClick={() => send({ type: "GO_TO_FILE_SELECTION" })}
      >
        {t("figjamError.actionButton")}
      </Button>
    </div>
  );
};
