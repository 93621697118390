import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IIconType } from "@/icons";

interface IError {
  title?: string;
  description?: string;
  icon?: IIconType;
}

type IProps = IError & IWithCustomStyle;

export const Error: React.FC<IProps> = ({
  title,
  description,
  icon,
  children,
  customStyle,
}) => {
  const { t } = useTranslation("converter");
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...customStyle,
      })}
    >
      {icon && (
        <StyledIconWrapper>
          <Icon iconType={icon} />
        </StyledIconWrapper>
      )}
      <Heading as="h4" customStyle={{ fontSize: 20, color: colors.RED }}>
        {title ?? t("error.title")}
      </Heading>

      <p
        className={css({
          marginTop: 10,
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 1.7,
          color: colors.MAASTRICHT_BLUE,
        })}
      >
        {description ?? t("error.description")}
      </p>

      {children}
    </div>
  );
};
