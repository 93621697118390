import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle } from "../../hooks/useTheme/types";

export const Notification: React.FC<IWithCustomStyle> = ({
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();

  return (
    <div
      className={css(
        {
          height: 60,
          width: "100%",
          display: "flex",
          borderRadius: 4,
          color: "#F8F8F8",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 10px 36px 0 rgba(11, 2, 35, 0.1)",
          background: "linear-gradient(62deg, #ff4343 -27%, #ff7c7c 85%)",
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
