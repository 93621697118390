import { PLANS_WITH_FREE_CREDITS } from "@/hooks/useAuth/types";
import { getConversionStatusForFormat } from "@/containers/Converter/common/helpers/getConversionStatusForFormat";
import { IConvertStatus } from "@/containers/Files/types";
import { isFreeConversion } from "@/utils/converter/isFreeConversion";
import { IContext, IFileDetailsResult } from "./types";
import { IConversionType } from "../../types";

export const fileStatusIsOneOf = (
  conversionFormat: IConversionType,
  statuses: IConvertStatus[],
) => (_ctx: IContext, event: IFileDetailsResult): boolean => {
  const status = getConversionStatusForFormat(event.data, conversionFormat);
  if (!status) return false;

  return statuses.includes(status);
};

export const notPAYGPayment = (ctx: IContext) => {
  const paymentType = ctx.paymentType?.toLowerCase() || "";

  return ["credits", "subscription"].some(
    item => paymentType.indexOf(item) > -1,
  );
};

export const userLoggedIn = (ctx: IContext) => ctx.user.loggedIn;

export const onCreditsPlan = (ctx: IContext) =>
  ctx.user.latestPlanType === "Credits";

export const onLifetimeSub = ({ user }: IContext) =>
  PLANS_WITH_FREE_CREDITS.includes(user.latestPlanType || "PayAsYouGo");

export const userHasFreeCredits = ({ user }: IContext) =>
  user.loggedIn && user.hasFreeCredits;

export const isFreeConversionCheck = (ctx: IContext) =>
  isFreeConversion(ctx.conversionType);
