import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import { Dropzone } from "@/components/Dropzone";
import { BetaForm } from "@/containers/BetaForm";
import { Heading } from "@/components/Heading";

import { ConversionFormatSelect } from "@/containers/Converter/common/components/ConversionFormatSelect";
import { IConversionType } from "@/containers/Converter/types";
import { getConversionDetails } from "@/containers/Converter/common/helpers";

type IProps = {
  conversionType: IConversionType;
  formats: IConversionType[];
  onConversionTypeChange: (newType: IConversionType) => void;
};

export const BetaSignUp: React.FC<IProps> = ({
  conversionType,
  formats,
  onConversionTypeChange,
}) => {
  const { css } = useFela();
  const { t } = useTranslation("converter");

  const { conversionSource, conversionTarget } = getConversionDetails(
    conversionType!,
  );

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
      })}
    >
      <BetaForm
        title={
          <Trans
            i18nKey="converter:betaSignup.title"
            components={[<span key={0} />]}
          />
        }
        subtitle={t("betaSignup.subtitle", {
          source:
            conversionSource === "xd"
              ? t("fileTypes:xd")
              : t(`fileTypes:${conversionSource}`),
          target:
            conversionTarget === "xd"
              ? t("fileTypes:xd")
              : t(`fileTypes:${conversionTarget}`),
        })}
        betaType={conversionType}
      />

      <Heading as="h4">{t("selectingFile.dragNDrop.title")}</Heading>

      <Dropzone disabled onDrop={() => null} />

      <ConversionFormatSelect
        conversionType={conversionType}
        disabled={false}
        formats={formats}
        onConversionTypeChange={onConversionTypeChange}
      />
    </div>
  );
};
