import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";
import { DynamicContent } from "@/components/DynamicContent";
import { logger } from "@/utils/logger";
import { LegalText } from "@/containers/Converter/LegalText";
import { Error } from "@/containers/Converter/common/components/Error";

import { ConverterContainer } from "@/containers/Converter/ConverterContainer";
import { conversionFormats } from "./maps";
import { IConverter } from "./types";

export const Converter: React.FC<IConverter> = ({
  disabled = false,
  inheritConversionTypeFromRoute = true,
  formats = conversionFormats,
}) => {
  const { bp } = useTheme();

  return (
    <DynamicContent>
      <Card
        customStyle={{
          minHeight: 380,
          maxWidth: 560,
          position: "relative",
          [bp.FROM_TABLET]: {
            minHeight: 470,
            padding: 30,
          },
          [bp.FROM_DESKTOP]: {
            marginTop: 50,
          },
        }}
      >
        <ErrorBoundary
          FallbackComponent={() => <Error />}
          onError={error => {
            logger.error(error);
          }}
        >
          <ConverterContainer
            disabled={disabled}
            inheritConversionTypeFromRoute={inheritConversionTypeFromRoute}
            formats={formats}
          />
        </ErrorBoundary>

        <LegalText />
      </Card>
    </DynamicContent>
  );
};
