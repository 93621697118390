import { Action, assign } from "xstate";
import { ISetUploadMachineResult } from "@/containers/Converter/UploadFile/state/fileUpload/types";
import * as Sentry from "@sentry/node";
import { redirectToFilePage } from "@/containers/Converter/common/helpers/redirectToFilePage";
import { sanitizeFileName } from "@/utils/sanitizeFileName";
import {
  IChangeFileURLEvent,
  IContext,
  ISelectFileEvent,
  ISetConversionType,
  ISetUploadProgress,
  ISetUserEmail,
  ISubmitFileForm,
  ISubmitFileUrl,
} from "./types";

export const selectFile = assign<IContext, ISelectFileEvent>({
  file: (_ctx, event) => {
    const originalFile = event.file;
    const sanitizedFileName = sanitizeFileName(originalFile.name);
    return new File([originalFile], sanitizedFileName, {
      type: originalFile.type,
    });
  },
});

export const changeFileUrl = assign<IContext, IChangeFileURLEvent>({
  fileURL: (_ctx, event) => event.fileURL,
});

export const setConversionType = assign<IContext, ISetConversionType>({
  conversionType: (_ctx, event) => event.conversionType,
});

export const submitFileURLData = assign<IContext, ISubmitFileUrl>({
  fileURL: (_ctx, event) => event.fileURL,
  userEmail: (_ctx, event) => event.userEmail,
});

export const submitFileForm = assign<IContext, ISubmitFileForm>({
  userEmail: (ctx, event) => {
    if (ctx.loggedIn && ctx.userEmail) {
      return ctx.userEmail;
    }
    return event.userEmail;
  },
});

export const resetFile = assign<IContext, any>({
  file: null,
});

export const setUploadProgress = assign<IContext, ISetUploadProgress>({
  uploadProgress: (_ctx, event) => event.uploadProgress,
});

export const setUserEmail = assign<IContext, ISetUserEmail>({
  userEmail: (_ctx, event) => event.userEmail,
});

export const setUploadMachineResult = assign<IContext, ISetUploadMachineResult>(
  {
    errorType: (_ctx, event) => event.errorType,
    uploadedFileName: (_ctx, event) => event.uploadedFileName,
    newUser: (_ctx, event) => event.isNewUser,
  },
);

export const redirectToPreview: Action<IContext, any> = ctx => {
  const { uploadedFileName } = ctx;
  if (!uploadedFileName) {
    throw Error("Invalid state: uploadFileName is null");
  }

  redirectToFilePage({
    fileName: uploadedFileName,
    conversionType: ctx.conversionType,
    newUser: ctx.newUser || false,
    userEmail: ctx.userEmail || undefined,
  });
};

export const logError = (ctx: IContext) => {
  const { errorType, userEmail, conversionType, file, uploadedFileName } = ctx;

  const originalFileData = {
    name: file?.name || "",
    size: file?.size || 0,
  };

  Sentry.withScope(scope => {
    scope.setExtras({
      errorType,
      userEmail,
      conversionType,
      originalFileData,
      uploadedFileName,
    });
    scope.setTag("where", "File upload");
    Sentry.captureException(new Error(`File upload error: ${errorType}`));
  });
};
