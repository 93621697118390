import React from "react";
import { useFela } from "react-fela";

import { Icon, StyledIconWrapper } from "@/components/Icon";
import { Button } from "@/components/Button";
import { TextBadge } from "@/components/Badge";
import { IIconType } from "@/icons";
import { IButtonVariant } from "@/components/Button/types";
import { useTheme } from "@/hooks/useTheme";

interface IProps {
  badgeText: string;
  promotionText: string;
  fileIconType: IIconType;
  badgeIconType?: IIconType;
  action: {
    title: string;
    event: () => void;
    variant?: IButtonVariant;
    testId?: string;
  };
}

export const ConversionPlan: React.FC<IProps> = ({
  action,
  badgeText,
  promotionText,
  fileIconType,
  badgeIconType,
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [bp.FROM_TABLET]: {
          alignItems: "flex-end",
          justifyContent: "space-around",
          flexDirection: "row",
        },
      })}
    >
      <div
        className={css({
          height: 94,
          position: "relative",
          [bp.FROM_TABLET]: {
            height: 64,
          },
        })}
      >
        <StyledIconWrapper customStyle={{ width: 64, height: 64 }}>
          <Icon iconType={fileIconType} />
        </StyledIconWrapper>

        {badgeText && (
          <TextBadge
            customStyle={{
              position: "absolute",
              bottom: 5,
              left: -3,

              [bp.FROM_TABLET]: {
                bottom:
                  fileIconType === "ADOBEXD" ||
                  fileIconType === "PHOTOSHOP" ||
                  fileIconType === "AI"
                    ? -17
                    : 15,
                left:
                  fileIconType === "ADOBEXD" ||
                  fileIconType === "PHOTOSHOP" ||
                  fileIconType === "AI"
                    ? -3
                    : 30,
                zIndex: 10,
              },
            }}
          >
            {badgeIconType && <Icon iconType={badgeIconType} />}
            {badgeText}
          </TextBadge>
        )}
      </div>

      <div
        className={css({
          textAlign: "center",
          marginTop: 10,
          [bp.FROM_TABLET]: {
            marginTop: 0,
          },
        })}
      >
        <p
          className={css({
            fontSize: 14,
            marginBottom: 2,
            fontWeight: 600,
            lineHeight: 1.71,
            color: colors.MAASTRICHT_BLUE,
          })}
        >
          {promotionText}
        </p>

        <Button
          data-testid={action.testId}
          onClick={action.event}
          variant={action.variant}
          customStyle={{ minWidth: 184 }}
        >
          {action.title}
        </Button>
      </div>
    </div>
  );
};
