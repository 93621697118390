import { IResponse as IFileDetailsResponse } from "@/services/fileDetailsService";
import { IConvertStatus } from "@/containers/Files/types";
import { getConversionDetails } from "./getConversionDetails";
import { IConversionType } from "../../types";

export const getConversionStatusForFormat = (
  fileDetails: IFileDetailsResponse,
  conversionFormat: IConversionType,
): IConvertStatus | undefined => {
  const { conversionTarget } = getConversionDetails(conversionFormat);
  const targetFile = fileDetails[conversionTarget];
  return targetFile?.full?.status || undefined;
};
