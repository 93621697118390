import React from "react";
import Circle from "react-circle";

import { useTheme } from "@/hooks/useTheme";

interface IProgressSpinner {
  percentage: number;
  color: string;
}

export const ProgressSpinner: React.FC<IProgressSpinner> = ({
  color,
  percentage,
}) => {
  const { colors } = useTheme();
  return (
    <Circle
      animate={true}
      animationDuration="1s"
      responsive={false}
      size="138"
      lineWidth="20"
      progress={percentage || 0}
      progressColor={color}
      bgColor="#ecedf0"
      textColor={colors.DARK_GREY}
      textStyle={{
        font: "bold 85px Nunito Sans, Arial, sans-serif",
      }}
      percentSpacing={10}
      roundedStroke={true}
      showPercentage={true}
      showPercentageSymbol={true}
    />
  );
};
