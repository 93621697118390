import React from "react";
import { useFela } from "react-fela";

import { IDropzone } from "@/components/Dropzone/types";

import { ConversionFormatSelect } from "@/containers/Converter/common/components/ConversionFormatSelect";
import { extensionMap } from "@/containers/Converter/common/helpers/getConversionDetails";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { IConversionType } from "@/containers/Converter/types";
import { DragAndDropSelection } from "@/components/DragAndDropSelection";
import { TextareaSelection } from "./TextareaSelection";

interface IProps {
  conversionType: IConversionType;
  formats: IConversionType[];
  fileURL?: string;
  disabled?: boolean;
  onFileSelected: (file: File) => void;
  onFileUrlChange: (fileURL: string) => void;
  onFileUrlSubmit: (fileURL: string, userEmail: string) => void;
  onConversionTypeChange: (newType: IConversionType) => void;
}

export const SelectingFile: React.FC<IProps> = props => {
  const { css } = useFela();
  const {
    conversionType,
    formats,
    fileURL,
    disabled,
    onFileSelected,
    onFileUrlChange,
    onFileUrlSubmit,
    onConversionTypeChange,
  } = props;

  const { conversionSource: sourceConversionType } = getConversionDetails(
    conversionType,
  );

  const acceptedFilesFormats = Object.values(extensionMap).filter(
    extension =>
      ![".fig", ".jam", ".googleslides", ".canva", ".miro", ".jpg"].includes(
        extension,
      ),
  );

  const handleFileDrop: IDropzone["onDrop"] = files => {
    if (files.length > 0) {
      onFileSelected(files[0]);
    }
  };

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
      })}
    >
      {["figma", "googleslides", "canva", "figjam"].includes(
        sourceConversionType,
      ) ? (
        <TextareaSelection
          conversionType={conversionType}
          fileURL={fileURL}
          onFileUrlSubmit={onFileUrlSubmit}
          onFileUrlChange={onFileUrlChange}
          displayInstructions={sourceConversionType === "canva"}
        >
          <ConversionFormatSelect
            conversionType={conversionType}
            disabled={false}
            formats={formats}
            onConversionTypeChange={onConversionTypeChange}
          />
        </TextareaSelection>
      ) : (
        <DragAndDropSelection
          acceptedFilesFormats={acceptedFilesFormats}
          handleFileDrop={handleFileDrop}
          disabled={disabled}
        >
          <ConversionFormatSelect
            conversionType={conversionType}
            disabled={false}
            formats={formats}
            onConversionTypeChange={onConversionTypeChange}
          />
        </DragAndDropSelection>
      )}
    </div>
  );
};
