import { InvokeCreator } from "xstate";
import {
  fetchFileDetails,
  IResponse as FileDetailsResponse,
} from "@/services/fileDetailsService";
import { getCheckoutPricingPlan } from "@/containers/Converter/common/helpers/getCheckoutPricingPlan";
import { PRODUCTS } from "@/constants/products";
import { handleCheckout } from "@/services/checkoutService";
import { payWithCredits } from "@/services/converterService";
import { logger } from "@/utils/logger";
import { IContext, IEvent } from "./types";

export const fetchFileService: InvokeCreator<IContext, any> = (
  ctx,
): Promise<FileDetailsResponse> => {
  const fileName = ctx.uploadedFileName;
  if (!fileName) {
    throw Error("Invalid state: ctx.uploadedFileName is null");
  }

  return fetchFileDetails(fileName);
};

export const handleCheckoutService: InvokeCreator<
  IContext,
  IEvent
> = ctx => send => {
  const paymentType = ctx.paymentType;
  const fileName = ctx.uploadedFileName || "";
  const numberOfArtboards = ctx.fileDetails?.artboardsCount || 1;
  const conversionType = ctx.conversionType || "";
  const email = ctx.user.email || "";

  const checkoutPlan = getCheckoutPricingPlan(paymentType!);

  const planId = PRODUCTS[checkoutPlan];

  handleCheckout({
    fileName,
    planId,
    conversionType,
    numberOfArtboards,
    email,
  })
    .then(res =>
      send({
        type: "COMPLETE_CHECKOUT",
        hostedPageId: res.hostedPageId,
        isNewUser: res.isNewUser,
      }),
    )
    .catch(err => {
      logger.warn(`Checkout failed: ${err}`);
      send({
        type: "CLOSE_CHECKOUT",
      });
    });
};

export const deductCreditsService: InvokeCreator<IContext> = async (
  ctx,
): Promise<{ message: string }> => {
  return payWithCredits({
    fileName: ctx.uploadedFileName,
    conversionType: ctx.conversionType,
  });
};
