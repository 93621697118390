import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Error } from "@/containers/Converter/common/components/Error";
import { Button } from "@/components/Button";

interface IProps {
  retryConversion: () => void;
}

declare const Intercom: (...args: unknown[]) => any;

export const ConversionError: React.FC<IProps> = props => {
  const { t } = useTranslation("converter");
  const { retryConversion } = props;

  const handleEvent = () => {
    if (typeof Intercom !== "undefined") {
      Intercom("showNewMessage");
    }
  };

  return (
    <Error
      customStyle={{
        justifyContent: "stretch",
        nested: { "> div": { width: "100%" } },
      }}
    >
      <Button
        customStyle={{
          marginTop: 24,
          maxWidth: 238,
          textAlign: "center",
        }}
        onClick={retryConversion}
      >
        {t("error.restart")}
      </Button>
      <Button
        variant="OUTLINED"
        customStyle={{
          marginTop: 20,
          maxWidth: 238,
          textAlign: "center",
        }}
        onClick={handleEvent}
      >
        {t("error.contactSupport")}
      </Button>
    </Error>
  );
};
