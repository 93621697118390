/**
 * Replaces the file extension with an identical format (e.g. psb to psb)
 * @param  {string} fileName
 * @returns {string} the resulting filename
 */
const replaceIdenticalFileExtension = (fileName: string) => {
  return fileName.replace(".psb", ".psd");
};
/**
 * Sanitizes the file name, by removing unsupported characters, trimming it
 * to the maximum length and by replacing identical file extensions
 * E.g. replacing .psb with .psd
 * @param  {string} fileName
 * @returns {string} resulting file name
 */
export const sanitizeFileName = (fileName: string) => {
  let newFileName = fileName.slice();
  if (newFileName.includes("$")) {
    newFileName = newFileName.replaceAll("$", "");
  }
  if (newFileName.length > 100) {
    const fileExt = newFileName.split(".")?.pop();
    newFileName = `${fileName.substring(0, 80).trim()}.${fileExt}`;
  }
  return replaceIdenticalFileExtension(newFileName);
};
