import React from "react";
import { useFela } from "react-fela";
import { Icon } from "../../../../../components/Icon";
import { useTheme } from "../../../../../hooks/useTheme";

export function HasMissingLinkedElements() {
  const { css } = useFela();
  const { colors, fonts } = useTheme();

  return (
    <div
      className={css({
        flex: 1,
        backgroundColor: colors.LIGHT_RED,
        borderRadius: "8px",
        marginTop: "23px",
        padding: "16px 25px 16px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        justifyContent: "center",
      })}
    >
      <div
        className={css({
          marginRight: "8px",
          marginTop: "2px",
        })}
      >
        <Icon iconType="EXCLAIMATION-ICON" />
      </div>
      <div>
        <p
          className={css({
            fontFamily: fonts.PRIMARY_FONT,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "22px",
            color: colors.DARK_GREY,
          })}
        >
          Warning: Make sure all assets are embedded
        </p>
        <p
          className={css({
            fontFamily: fonts.PRIMARY_FONT,
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            color: colors.LIGHT_GRAY,
            marginTop: "4px",
          })}
        >
          Please ensure that all linked assets (e.g. images) are embedded in
          your InDesign file. Otherwise, linked assets may convert as
          low-resolution images.{" "}
          <a
            className={css({
              color: colors.DARK_GREY,
              fontWeight: 600,
              textDecoration: "underline",
            })}
            href="https://help.magicul.io/en/articles/8833464-how-to-embed-images-in-indesign-files"
            target="_blank"
            rel="noreferrer"
          >
            Read more.
          </a>
        </p>
      </div>
    </div>
  );
}
