import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { getFlashSaleEndDate } from "@/utils/getFlashSaleEndDate";
import { Heading } from "@/components/Heading";
import { Modal } from "@/components/Modal";
import { useTheme } from "@/hooks/useTheme";
import { FlashSale } from "@/components/FlashSale";
import { PRICING } from "@/constants/pricing";
import { PaymentGuarantee } from "@/containers/PaymentGuarantee";
import { PricesCurrencyText } from "@/containers/Pricing/PricesCurrencyText";
import { IConversionType, IPaymentType } from "@/containers/Converter/types";
import { AlreadyACustomer } from "@/components/AlreadyACustomer";
import {
  PayAsYouGoCard,
  CreditsCard,
  SubscriptionCard,
} from "@/containers/Pricing/Cards";

interface IProps {
  conversionType: IConversionType;
  onPaymentTypeSelected: (plan: IPaymentType) => void;
  afterUserLoginViaAlreadyACustomerBtn: () => void;
  onModalWindowClose: () => void;
  artboardsCount: number;
}

export const Payment: React.FC<IProps> = props => {
  const { css } = useFela();
  const { bp } = useTheme();
  const { t } = useTranslation();

  const {
    onPaymentTypeSelected,
    artboardsCount,
    afterUserLoginViaAlreadyACustomerBtn,
    onModalWindowClose,
  } = props;

  const handleClose = () => {
    const isVisible =
      window.document.getElementById("cb-container")?.style.display !== "none";
    if (!isVisible) {
      onModalWindowClose();
    }
  };

  const handleOneTimePayment = (artboardCount: number) => {
    if (artboardCount > PRICING.SMALL_FILE_ARTBOARD_LIMIT) {
      onPaymentTypeSelected("ONE_TIME_PAYMENT_LARGE");
    } else {
      onPaymentTypeSelected("ONE_TIME_PAYMENT");
    }
  };

  return (
    <Modal
      id="payment-modal"
      onClose={handleClose}
      customStyle={{
        maxHeight: "95vh",
        padding: "20px",
        scrollbarWidth: "none",
        [bp.FROM_DESKTOP]: {
          padding: "20px 40px",
        },
        [bp.FROM_LARGE_DESKTOP]: {
          padding: "20px 100px",
        },
        nested: {
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <div
        className={css({
          textAlign: "center",
        })}
      >
        <Heading
          as="h2"
          customStyle={{
            [bp.FROM_DESKTOP]: { fontSize: 28 },
          }}
        >
          {t("payment:title")}
        </Heading>
      </div>

      <FlashSale
        title="common:flashsale.title"
        subtitle={t("common:flashsale.description", {
          date: getFlashSaleEndDate("en-US", {
            day: "numeric",
            month: "long",
          }),
        })}
        tooltip={t("common:flashsale.tooltip")}
        onClick={() => onPaymentTypeSelected("SUBSCRIPTION_UNLIMITED_ANNUAL")}
        customStyle={{ marginBottom: "-24px" }}
      />

      <AlreadyACustomer onUserLogIn={afterUserLoginViaAlreadyACustomerBtn} />
      <div
        className={css({
          marginTop: 40,
          [bp.FROM_TABLET]: {
            marginTop: 60,
          },
          [bp.FROM_DESKTOP]: {
            marginTop: 30,
          },
        })}
      >
        <PricesCurrencyText
          customStyle={{
            padding: "0 40px 15px",
            textAlign: "right",
            [bp.FROM_DESKTOP]: {
              padding: "0 30px 15px",
            },
          }}
        />

        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            nested: {
              "> div:not(:first-of-type)": {
                marginTop: 20,
                [bp.FROM_DESKTOP]: {
                  marginTop: 0,
                  marginLeft: 30,
                },
              },
            },
            [bp.FROM_DESKTOP]: {
              flexDirection: "row",
              justifyContent: "space-around",
            },
          })}
        >
          <PayAsYouGoCard
            fileInfo={{
              artboardsCount,
            }}
            cta={{
              title: t("common:actions.doNow", {
                do: t("common:actions.pay"),
              }),
              onClick: () => {
                handleOneTimePayment(artboardsCount);
              },
            }}
          />

          <CreditsCard
            cta={{
              title: t("common:actions.doNow", {
                do: t("common:actions.pay"),
              }),
              onClick: onPaymentTypeSelected,
            }}
          />

          <SubscriptionCard
            cta={{
              title: t("common:actions.doNow", {
                do: t("common:actions.pay"),
              }),
              onClick: onPaymentTypeSelected,
            }}
          />
        </div>
      </div>
      <div
        className={css({
          margin: "40px 0",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          [bp.FROM_DESKTOP]: {
            justifyContent: "space-around",
            flexWrap: "no-wrap",
          },
        })}
      >
        <PaymentGuarantee customStyle={{ width: "90%" }} />
      </div>
    </Modal>
  );
};
