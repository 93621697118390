import { Action, assign, DoneInvokeEvent, send } from "xstate";
import { logger } from "@/utils/logger";
import {
  IContext,
  IEvent,
  IFileDetailsResult,
  ISetConversionStatus,
} from "./types";

export const setConversionStatus = assign<IContext, ISetConversionStatus>({
  conversionStatus: (_ctx, event) => event.status,
});

export const sendHandleConversionStatusEvent = send<IContext, any, IEvent>({
  type: "HANDLE_CONVERSION_STATUS",
});

export const setFileDetails = assign<IContext, IFileDetailsResult>({
  fileDetails: (_ctx, event) => event.data,
});

export const setError = assign<IContext, DoneInvokeEvent<any>>({
  error: (ctx, event) => {
    if (event.data instanceof Error) {
      return event.data;
    }
    return ctx.error;
  },
});

export const clearError = assign<IContext, any>({
  error: null,
});

export const logError: Action<IContext, any> = ctx => {
  if (ctx.error) {
    logger.error("Error happened in ConvertAndView machine", ctx.error);
  }
};
