import {
  IConversionType,
  IConverterContext,
} from "@/containers/Converter/types";
import { IFilesDetails } from "@/containers/Converter/common/types";
import { IContext } from "./types";

export type IConvertAndViewMachineArgs = {
  conversionType: IConversionType;
  uploadedFileName: string;
  fileDetails: IFilesDetails;
};

export function getMachineArgs(
  converterCtx: IConverterContext,
): IConvertAndViewMachineArgs {
  const { uploadedFileName, fileDetails } = converterCtx;
  if (!uploadedFileName) {
    throw Error("Invalid state: uploadedFileName is null");
  }
  if (!fileDetails) {
    throw Error("Invalid state: uploadedFileDetails is null");
  }

  return {
    conversionType: converterCtx.conversionType,
    fileDetails,
    uploadedFileName,
  };
}

export function getInitialContext(args: IConvertAndViewMachineArgs): IContext {
  return {
    conversionStatus: null,
    errorType: null,
    hostedPageId: null,
    error: null,
    ...args,
  };
}
