import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";

import { ISelect, IOption } from "./types";
import { Option } from "./Option";

type IProps = ISelect & IWithCustomStyle;

export const Select: React.FC<IProps> = ({
  disabled,
  options,
  value,
  onOptionSelect,
  testId,
  customStyle = {},
  optionStyle = {},
}) => {
  const { css } = useFela();
  const { colors } = useTheme();

  const ref = React.useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);

  useOnClickOutside(ref, () => {
    setIsExpanded(false);
  });

  const handleToggle = () => {
    if (!disabled) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleOptionSelect = (value: string) => {
    onOptionSelect(value);
    setIsExpanded(false);
  };

  const currentOption = options.find(opt => opt.value === value);

  return (
    <div
      ref={ref}
      className={css({ position: "relative", ...customStyle })}
      data-testid={testId}
    >
      <div
        role="button"
        className={css({
          height: 50,
          backgroundColor: "#fafafc",
          color: colors.DARK_GREY,
          fontWeight: "bold",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: `1px solid ${colors.LIGHT_GRAY3}`,
          cursor: disabled ? "not-allowed" : "pointer",
          filter: disabled ? "grayscale(1)" : "none",
          ...optionStyle,
        })}
      >
        <span
          className={css({
            lineHeight: 1.9,
            width: "100%",
            position: "relative",
          })}
        >
          <Option
            {...currentOption!}
            onClick={handleToggle}
            customStyle={{
              nested: {
                "> *": {
                  marginRight: 10,
                },
                ":not(:last-child)": {
                  borderBottom: "none",
                },
              },
            }}
          />
          <span
            className={css({
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: " 0 4px 4px 4px",
              borderColor: `transparent transparent ${colors.DARK_GREY} transparent`,
              transform: isExpanded ? "rotate(0deg)" : "rotate(-180deg)",
              position: "absolute",
              right: 15,
              top: "50%",
              bottom: "50%",
            })}
          />
        </span>
      </div>
      <ul
        role="listbox"
        className={css({
          color: colors.DARK_GREY,
          position: "absolute",
          top: "100%",
          left: 0,
          right: 0,
          backgroundColor: "#fafafc",
          outline: "none",
          opacity: isExpanded ? 1 : 0,
          transition: "opacity .15s",
          zIndex: 30,
          listStyleType: "none",
          padding: 0,
          margin: 0,
        })}
      >
        <div
          className={css({
            minHeight: 0,
            maxHeight: isExpanded ? 364 : 0,
            width: "100%",
            transition: "max-height .15s",
            overflowY: isExpanded ? "scroll" : "hidden",
            boxShadow: "1px 2px 10px rgba(0,0,0, .1)",
          })}
        >
          {options.map(opt => (
            <Option
              key={opt.id || opt.value}
              {...opt}
              onClick={() => {
                handleOptionSelect(opt.value);
              }}
              customStyle={optionStyle}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export type { ISelect, IOption };
