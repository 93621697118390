import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { Button } from "@/components/Button";

export const TrialUsed: React.FC = () => {
  const { css } = useFela();
  const { t } = useTranslation();
  const { colors, bp } = useTheme();
  const { push } = useRouter();

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 30,
      })}
      data-testid="converter-step--trial-used"
    >
      <StyledIconWrapper
        wrapperStyle={{
          width: 90,
          height: 90,
          borderRadius: "50%",
          backgroundColor: colors.DANGER_RED,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Icon iconType="WARNING" />
      </StyledIconWrapper>

      <Heading
        customStyle={{
          color: colors.CHARCOAL,
          [bp.FROM_DESKTOP]: {
            maxWidth: 350,
            fontSize: 28,
            lineHeight: "36px",
            letterSpacing: 1,
          },
        }}
      >
        {t("converter:trialUsed.title")}
      </Heading>

      <p
        className={css({
          color: colors.DARK_GREY,
          fontSize: 14,
          lineHeight: "24px",
          marginBottom: 20,
        })}
      >
        {t("converter:trialUsed.description")}
      </p>

      <p
        className={css({
          color: colors.PURLPE,
          fontSize: 16,
          lineHeight: "24px",
          letterSpacing: 0.5,
          fontWeight: "bold",
          marginBottom: 25,
        })}
      >
        {t("converter:trialUsed.ctaText")}
      </p>

      <Button
        customStyle={{ maxWidth: 300 }}
        onClick={() => {
          push("/");
        }}
      >
        {t("converter:trialUsed.ctaButton")}
      </Button>
    </div>
  );
};
