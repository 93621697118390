import Trans from "next-translate/Trans";
import { Link } from "@/components/Link";
import React from "react";
import { useFela } from "react-fela";
import { useTheme } from "@/hooks/useTheme";

export const LegalText: React.FC = () => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <p
      data-testid="converter-legal-text"
      className={css({
        fontSize: 14,
        marginTop: 30,
        textAlign: "center",
        color: colors.DARK_GREY,
        nested: {
          "> a": {
            margin: "0 1px",
            fontWeight: 600,
            letterSpacing: 0.5,
            color: colors.RED,
            textDecoration: "underline",
            transition: "opacity .15s",
            nested: {
              ":hover": { opacity: 0.8 },
            },
          },
        },
      })}
    >
      <Trans
        i18nKey="converter:legalText"
        components={[
          <Link key="0" href="/terms-of-service" />,
          <Link key="1" href="/privacy" />,
        ]}
      />
    </p>
  );
};
