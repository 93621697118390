import { IPreviewAndPaymentMachineArgs } from "@/containers/Converter/PreviewAndPayment/state/machineArguments";
import { IContext } from "./types";

export function getInitialContext(
  args: IPreviewAndPaymentMachineArgs,
): IContext {
  return {
    paymentType: null,
    file: null,
    fileDetails: null,
    errorType: null,
    hostedPageId: null,
    ...args,
  };
}
