import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import { useTheme } from "@/hooks/useTheme";
import { Heading } from "@/components/Heading";
import { StyledIconWrapper, Icon } from "@/components/Icon";
import { Button } from "@/components/Button";

interface IProps {
  onRetry: () => void;
  onFigmaConnect: () => void;
}

export const FileNotPublicError: React.FC<IProps> = ({
  onRetry,
  onFigmaConnect,
}) => {
  const { css } = useFela();
  const { colors } = useTheme();
  const { t } = useTranslation("converter");

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px 0",
      })}
    >
      <StyledIconWrapper>
        <Icon iconType="CONNECT-FIGMA-ICON" />
      </StyledIconWrapper>
      <Heading
        as="h4"
        customStyle={{ margin: "32px 0 0", fontSize: 20, color: "#242628" }}
      >
        {t("fileNotPublic.title")}
      </Heading>

      <p
        className={css({
          marginTop: 10,
          fontSize: 16,
          maxWidth: 400,
          lineHeight: 1.7,
          color: colors.DARK_GREY,
          textAlign: "center",
        })}
      >
        <Trans
          i18nKey="converter:fileNotPublic.followInstruction"
          components={[
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            <a
              className={css({
                fontWeight: "bold",
                letterSpacing: 0.5,
                color: colors.RED,
                textDecoration: "none",
              })}
              key={0}
              target="_blank"
              rel="noreferrer"
              href="https://magicul.io/blog/how-to-temporarily-make-a-figma-file-public"
            />,
          ]}
        />
      </p>
      <div
        className={css({
          maxWidth: 280,
          width: "100%",
          marginTop: 32,
          display: "flex",
          flexDirection: "column",
          gap: 12,
        })}
      >
        <Button onClick={onFigmaConnect}>
          {t("fileNotPublic.actionButton")}
        </Button>

        <Button variant="OUTLINED" onClick={onRetry}>
          {t("fileNotPublic.actionButtonCancel")}
        </Button>
      </div>
    </div>
  );
};
