import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "@/components/Heading";
import { ProgressSpinnerScreen } from "@/containers/ProgressSpinnerScreen";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { Notification } from "@/components/Notification";

import { ProgressSpinner } from "@/components/ProgressSpinner";
import { useTheme } from "@/hooks/useTheme";
import { IConvertStatusResponse } from "@/services/converterService";

interface IProps {
  conversionData: IConvertStatusResponse | null;
}

export const Converting: React.FC<IProps> = props => {
  const { css } = useFela();
  const { t } = useTranslation("converter");
  const { colors, bp } = useTheme();

  const { conversionData } = props;

  const stage = conversionData?.stage;
  const hasImages = (conversionData?.totalImages ?? 0) > 0;
  const isImageUploadComplete =
    hasImages && conversionData?.totalImages === conversionData?.currentImage;
  const showArtboardProgress = !hasImages || isImageUploadComplete;
  const showImageProgress = hasImages && !isImageUploadComplete;
  const isDoneProcessing =
    hasImages || (conversionData?.totalArtboards ?? 0) > 0;
  const pagesData =
    conversionData &&
    conversionData.totalPages &&
    conversionData?.totalPages > 0
      ? {
          total: conversionData?.totalPages || 0,
          loaded: conversionData?.currentPage || 0,
        }
      : { total: 0, loaded: 0 };
  const progressData = { total: 0, loaded: 0, stage };

  if (showArtboardProgress) {
    progressData.total = conversionData?.totalArtboards || 0;
    progressData.loaded = conversionData?.currentArtboard || 0;
  } else {
    progressData.total = conversionData?.totalImages || 0;
    progressData.loaded = conversionData?.currentImage || 0;
  }

  const showOverallProgress = !!conversionData?.totalProgress;
  const showDetailedProgress = !showOverallProgress;

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
    >
      <Heading as="h4">{t("converting.title")}</Heading>

      {showDetailedProgress && showArtboardProgress && (
        <ProgressSpinnerScreen
          progressData={progressData}
          pagesData={pagesData}
          isConversion
          isDoneProcessing={isDoneProcessing}
          title={t("converting.convertingArtboard")}
          conversionStatus={conversionData?.status || "Uploaded"}
        />
      )}

      {showDetailedProgress && showImageProgress && (
        <ProgressSpinnerScreen
          progressData={progressData}
          pagesData={pagesData}
          isConversion
          isDoneProcessing={isDoneProcessing}
          title={t("converting.convertingImages")}
          conversionStatus={conversionData?.status || "Uploaded"}
        />
      )}

      {showOverallProgress && (
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 51,
            marginBottom: 23,
          })}
        >
          <ProgressSpinner
            color={colors.ORANGE}
            percentage={(conversionData?.totalProgress || 0) as number}
          />
        </div>
      )}

      {!hasImages && (
        <p
          className={css({
            fontSize: 14,
            fontWeight: 600,
            textAlign: "center",
            maxWidth: 240,
            lineHeight: 1.7,
            color: colors.MAASTRICHT_BLUE,
          })}
        >
          <strong>
            {stage === 2
              ? t("converting.finishing")
              : isDoneProcessing || showOverallProgress
              ? t("converting.pleaseWait")
              : t("converting.started")}
          </strong>
        </p>
      )}

      <Notification
        customStyle={{
          marginTop: 30,
          width: "calc(100% + 60px)",
          padding: "0 15px",
        }}
      >
        <p
          className={css({
            fontSize: 12,
            margin: "0 18px 0",
            textAlign: "center",
            [bp.FROM_TABLET]: { fontSize: 16 },
          })}
        >
          {t("converting.conversionInfoMessage")}
        </p>
        <StyledIconWrapper customStyle={{ flexShrink: 0 }}>
          <Icon iconType="INFO" />
        </StyledIconWrapper>
      </Notification>
    </div>
  );
};
