import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { getFileExtension } from "@/utils/getFileExtension";
import { IExtension } from "@/containers/Converter/types";
import { iconsMap, logoIconsMap } from "@/utils/iconMap";
import { Modal } from "../Modal";
import { Heading } from "../Heading";
import { Link } from "../Link";
import { Icon, StyledIconWrapper } from "../Icon";
import { ArticleItem } from "./ArticleItem";
import { helpArticles } from "./data";
import { IDownloadHelpArticleModal, IHelpArticle } from "./types";

export const DownloadHelpArticleModal: React.FC<IDownloadHelpArticleModal> = ({
  onClose,
  fileName,
  conversionType,
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation("downloadHelpArticleModal");
  const [articles, setArticles] = useState<IHelpArticle[]>([]);

  const extension = getFileExtension(fileName) as IExtension;

  const iconType =
    extension in iconsMap ? iconsMap[extension] : iconsMap[".fig"];
  const logoIconType =
    extension in logoIconsMap ? logoIconsMap[extension] : logoIconsMap[".fig"];

  useEffect(() => {
    const articles = helpArticles.filter(({ displayForFormat }) =>
      displayForFormat.includes(conversionType),
    );

    setArticles(articles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      id="success-payment-modal"
      onClose={onClose}
      customStyle={{
        maxHeight: "90vh",
        padding: "31px 56px 56px",
        [bp.FROM_LARGE_DESKTOP]: {
          width: "85%",
        },
        nested: {
          "> div": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 25,
          },
        },
      }}
    >
      <Heading
        as="h2"
        customStyle={{
          fontWeight: 600,
          [bp.FROM_TABLET]: { marginBottom: 10 },
        }}
      >
        {t("title")}
      </Heading>
      <Heading
        as="h4"
        customStyle={{
          display: "flex",
          alignItems: "center",
          marginBottom: 42,
          letterSpacing: "0.5px",
          color: colors.DARK_GREY,
        }}
      >
        <StyledIconWrapper
          wrapperStyle={{ display: "inline-block", height: 43 }}
          customStyle={{ flexShrink: 0 }}
        >
          <Icon iconType={iconType} />
        </StyledIconWrapper>
        <Trans
          i18nKey="downloadHelpArticleModal:downloadCount"
          components={[
            <span key="0" className={css({ margin: "0 4px 0 16px" })} />,
            <span
              key="1"
              className={css({
                fontWeight: 700,
                color: colors.CHARCOAL,
              })}
            >
              {fileName}
            </span>,
          ]}
        />
      </Heading>
      <span
        className={css({
          width: "100%",
          display: "block",
          height: 1,
          backgroundColor: "#D1D5DB",
        })}
      />
      <Heading
        as="h3"
        customStyle={{
          margin: "42px auto 16px",
          fontWeight: 400,
          textAlign: "center",
          [bp.FROM_TABLET]: { marginBottom: 16, fontSize: 28 },
        }}
      >
        {t("somethingWrongWithConverter")}
      </Heading>
      <Link
        href="https://help.magicul.io/en/"
        target="_blank"
        customStyle={{
          marginBottom: 42,
          fontSize: 16,
          lineHeight: "22px",
          fontWeight: 400,
          color: colors.GRAY,
        }}
      >
        {t("chechHelpCenter")} →
      </Link>
      <div
        className={css({
          width: "100%",
          display: "flex",
          flexDirection: "column",
          [bp.FROM_DESKTOP]: { flexDirection: "row" },
        })}
      >
        {articles.map(({ id, href, title, text }: IHelpArticle) => (
          <ArticleItem
            key={id}
            icon={logoIconType}
            title={title}
            text={text}
            href={href}
          />
        ))}
      </div>
    </Modal>
  );
};
