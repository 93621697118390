import { Machine } from "xstate";
import { getConversionStatusForFormat } from "@/containers/Converter/common/helpers/getConversionStatusForFormat";
import {
  getInitialContext,
  IConvertAndViewMachineArgs,
} from "@/containers/Converter/ConvertAndView/state/context";
import { conversionFailed, conversionIsDone } from "./guards";
import {
  pollConversionStatusService,
  convertFileService,
  fetchFileService,
} from "./services";
import { IContext, IEvent } from "./types";
import {
  clearError,
  logError,
  sendHandleConversionStatusEvent,
  setConversionStatus,
  setError,
  setFileDetails,
} from "./actions";

const getInitialState = (args: IConvertAndViewMachineArgs) => {
  const conversionStatus = getConversionStatusForFormat(
    args.fileDetails,
    args.conversionType,
  );
  if (!conversionStatus) return "startConversion";

  switch (conversionStatus) {
    case "Uploaded":
      return "startConversion";
    case "InProgress":
      return "pollingConversionStatus";
    case "Done":
      return "filePreview";
    case "Failed":
      return "error";
    default:
      throw Error(`Unknown conversion status: ${conversionStatus}`);
  }
};

export const createConvertAndViewMachine = (args: IConvertAndViewMachineArgs) =>
  Machine<IContext, any, IEvent>({
    id: "convertAndView",
    initial: getInitialState(args),
    context: getInitialContext(args),
    states: {
      startConversion: {
        invoke: {
          src: convertFileService,
          onDone: "pollingConversionStatus",
          onError: {
            actions: setError,
            target: "error",
          },
        },
      },
      pollingConversionStatus: {
        invoke: {
          src: pollConversionStatusService,
          onError: {
            actions: setError,
            target: "error",
          },
        },
        on: {
          SET_CONVERSION_STATUS: {
            actions: [setConversionStatus, sendHandleConversionStatusEvent],
          },
          HANDLE_CONVERSION_STATUS: [
            {
              cond: conversionIsDone,
              target: "fetchingFile",
            },
            {
              cond: conversionFailed,
              target: "error",
            },
          ],
        },
      },
      fetchingFile: {
        invoke: {
          src: fetchFileService,
          onDone: {
            actions: setFileDetails,
            target: "filePreview",
          },
          onError: {
            actions: setError,
            target: "error",
          },
        },
      },
      filePreview: {},
      error: {
        entry: [logError, clearError],
        on: {
          RETRY_CONVERSION: "startConversion",
        },
      },
    },
  });
