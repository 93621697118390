import React from "react";
import useTranslation from "next-translate/useTranslation";
import { CssFelaStyle } from "react-fela";

import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";
import { Dropdown } from "@/components/Dropdown";
import { BetaForm } from "@/containers/BetaForm";
import { IBetaType } from "@/containers/Converter/types";

interface IWaitlistDropdown {
  dropdownTitleStyles?: CssFelaStyle<unknown, unknown>;
  dropdownContentStyles?: CssFelaStyle<unknown, unknown>;
  betaFormStyles?: CssFelaStyle<unknown, unknown>;
  betaType?: IBetaType;
}

type IProps = IWaitlistDropdown & IWithCustomStyle;

export const WaitlistDropdown: React.FC<IProps> = ({
  dropdownTitleStyles,
  dropdownContentStyles,
  betaFormStyles,
  betaType,
}) => {
  const { colors, bp } = useTheme();
  const { t } = useTranslation();

  return (
    <Dropdown
      title={t("section/magicians:preferPlugin.join")}
      showDropdownIcon={false}
      showCloseButton={false}
      customStyle={{
        fontSize: 14,
        fontWeight: "bold",
        color: colors.RED,
        textDecoration: "underline",
        ...dropdownTitleStyles,
      }}
      contentCss={{
        top: 48,
        left: 45,
        [bp.ONLY_MOBILE]: {
          left: -24,
          top: 48,
        },
        ...dropdownContentStyles,
      }}
    >
      <BetaForm
        submitLabel={t("section/magicians:preferPlugin.join")}
        placeholder={t("section/magicians:preferPlugin.placeholder")}
        betaType={betaType || "PLUGIN"}
        customStyle={{
          padding: "10px 11px",
          [bp.ONLY_MOBILE]: {
            left: 136,
          },
          ...betaFormStyles,
        }}
      />
    </Dropdown>
  );
};
