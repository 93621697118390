import { Machine } from "xstate";
import { uploadMachine } from "@/containers/Converter/UploadFile/state/fileUpload/machine";
import { IConverterContext } from "@/containers/Converter/types";

import { getFileExtension } from "@/utils/getFileExtension";
import {
  changeFileUrl,
  logError,
  redirectToPreview,
  resetFile,
  selectFile,
  setConversionType,
  setUploadMachineResult,
  setUploadProgress,
  submitFileForm,
  submitFileURLData,
} from "./actions";
import { IContext, IEvent } from "./types";
import { createInitialContext } from "./context";
import { getInitialState } from "./helpers";

export const createUploadFileMachine = (converterCtx: IConverterContext) =>
  Machine<IContext, any, IEvent>({
    id: `uploadFile${converterCtx.userEmail || ""}`,
    initial: getInitialState(converterCtx),
    context: createInitialContext(converterCtx),
    states: {
      selectingFile: {
        on: {
          SELECT_FILE: {
            actions: selectFile,
            target: "droppedFile",
          },
          CHANGE_FILE_URL: {
            actions: changeFileUrl,
          },
          SUBMIT_FILE_URL: {
            actions: submitFileURLData,
            target: "uploading",
          },
          SET_CONVERSION_TYPE: {
            actions: setConversionType,
          },
        },
      },
      droppedFile: {
        on: {
          SUBMIT_FILE_FORM: {
            actions: submitFileForm,
            target: "uploading",
          },
          RESET_FILE: {
            actions: resetFile,
            target: "selectingFile",
          },
          SET_CONVERSION_TYPE: {
            actions: setConversionType,
          },
        },
      },
      uploading: {
        invoke: {
          src: uploadMachine,
          data: (ctx: IContext) => {
            if (
              ctx.file?.name &&
              getFileExtension(ctx.file.name) === ".xd" &&
              ctx.file.size < 500
            ) {
              ctx.errorType = "XD_CLOUD_FILE_ERROR";
            }
            return { ...ctx };
          },
          onDone: [
            {
              actions: redirectToPreview,
              cond: ctx => !ctx.errorType,
            },
            {
              target: "error",
            },
          ],
          onError: "error",
        },
        on: {
          SET_UPLOAD_PROGRESS: {
            actions: setUploadProgress,
          },
          SET_UPLOAD_MACHINE_RESULT: {
            actions: setUploadMachineResult,
          },
        },
      },
      error: {
        entry: logError,
        on: {
          RETRY_UPLOAD: {
            actions: resetFile,
            target: "selectingFile",
          },
        },
      },
      betaSignUp: {
        type: "final",
      },
      trialUsed: {
        type: "final",
      },
    },
  });
