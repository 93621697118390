import React, { ReactNode, useState } from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useAuth } from "@/hooks/useAuth";
import {
  IConversionTarget,
  IConversionType,
} from "@/containers/Converter/types";
import { Button } from "@/components/Button";
import { Heading } from "@/components/Heading";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IconMessage } from "@/components/IconMessage";
import { Input } from "@/components/Input";
import { Textarea } from "@/components/Textarea";
import { useTheme } from "@/hooks/useTheme";
import { validateEmail } from "@/utils/validateEmail";
import { validateFigmaFileURL } from "@/utils/validateFigmaFileURL";
import { validateFigJamFileURL } from "@/utils/validateFigJamFileURL";
import { validateGoogleSlidesFileURL } from "@/utils/validateGoogleSlidesFileURL";
import { validateCanvaURL } from "@/utils/validateCanvaURL";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { useSearchParams } from "@/hooks/useSearchParams";
import { QUERY_PARAMS_KEYS } from "@/constants/queryParams";

export interface IProps {
  conversionType: IConversionType;
  onFileUrlSubmit: (fileUrl: string, email: string) => void;
  onFileUrlChange: (fileUrl: string) => void;
  fileURL?: string;
  displayInstructions?: boolean;
}

export const TextareaSelection: React.FC<IProps> = props => {
  const { colors } = useTheme();
  const { css } = useFela();
  const { user, loggedIn, loading } = useAuth();
  const { t } = useTranslation("converter");

  // TODO: move state retrieving from search params to a higher level
  const searchParams = useSearchParams();
  const emailFromSearchParams =
    searchParams.get(QUERY_PARAMS_KEYS.figmaCallbackEmail) || "";
  const fileURLFromSearchParams =
    searchParams.get(QUERY_PARAMS_KEYS.figmaCallbackFileURL) || "";
  const [fileError, setFileError] = useState("");
  const [email, setEmail] = useState(emailFromSearchParams);
  const [isError, setIsError] = useState(false);

  const {
    conversionType,
    children,
    onFileUrlSubmit,
    onFileUrlChange,
    fileURL: fileURLFromProps,
  } = props;
  const fileURL = fileURLFromProps || fileURLFromSearchParams;
  const { conversionSource: sourceConversionType } = getConversionDetails(
    conversionType,
  );

  const textareaPlaceholder: Partial<Record<IConversionTarget, string>> = {
    figma: "https://www.figma.com/design/VhEpl555Xpdv49Q3xvixa1/",
    googleslides:
      "https://docs.google.com/presentation/d/1pGaNHSfilptk5Gq_1mzhYvwT_HTS5c-uTq_JCFOE/edit?usp=sharing",
    canva: "https://www.canva.com/design/DAEiaPCL1ew/cm8muPCM2tDmNiLoclG4uw/",
    figjam: "https://www.figma.com/board/VhEpl555Xpdv49Q3xvixa1/",
  };

  const validateUrlHelper: Partial<Record<IConversionTarget, Function>> = {
    figma: validateFigmaFileURL,
    figjam: validateFigJamFileURL,
    googleslides: validateGoogleSlidesFileURL,
    canva: validateCanvaURL,
  };

  const instructions: Partial<Record<IConversionTarget, ReactNode>> = {
    figma: (
      <a
        href="https://help.magicul.io/en/articles/9792712-how-to-convert-authorize-magicul-to-convert-private-figma-files"
        target="_blank"
        rel="noopener noreferrer"
        className={css({
          color: colors.RED,
          fontWeight: 700,
          marginLeft: "auto",
        })}
      >
        {t("section/magicians:seeHowItWorks")}
      </a>
    ),
    canva: (
      <p
        className={css({
          color: colors.DARK_GREY,
          fontWeight: 700,
          marginLeft: "auto",
        })}
      >
        NOTE: Your Canva design has to be public.{" "}
        <a
          href="https://magicul.io/blog/how-to-temporarily-make-a-canva-design-public"
          rel="noopener noreferrer"
          target="_blank"
          className={css({
            color: colors.RED,
            fontWeight: 700,
            marginLeft: "auto",
          })}
        >
          Read more.
        </a>
      </p>
    ),
  };

  const handleTextareaChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
    onFileUrlChange(event.target.value);
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    setFileError("");
    if (!fileURL) {
      setFileError(
        t(`selectingFile.textArea.errors.${sourceConversionType}.empty`),
      );
      return;
    }

    if (!loggedIn && !loading) {
      const isValidEmail = validateEmail(email);

      if (!isValidEmail) {
        setIsError(true);
        return;
      }
    }

    const validationFunction =
      validateUrlHelper[sourceConversionType || "figma"];
    const isValidFile = validationFunction && validationFunction(fileURL);

    if (!isValidFile) {
      setFileError(
        t(`selectingFile.textArea.errors.${sourceConversionType}.incorrect`),
      );
    } else {
      const userEmail = user?.email || email;
      onFileUrlSubmit(fileURL, userEmail);
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.target.value);
    setIsError(false);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {sourceConversionType && (
        <Heading as="h4">
          {t(`selectingFile.textArea.title.${sourceConversionType}`)}
        </Heading>
      )}

      <>
        <Textarea
          data-testid="converter-textarea-input"
          value={fileURL}
          onChange={handleTextareaChange}
          rows={3}
          placeholder={`e.g. ${
            textareaPlaceholder[sourceConversionType || "figma"]
          }`}
          customStyle={{
            resize: "none",
            margin: "20px 0 8px",
            color: colors.DARK_GREY,
            nested: {
              "::placeholder": {
                color: "#A3A7AC",
              },
            },
          }}
        />
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 15,
            fontSize: 12,
          })}
        >
          {instructions[sourceConversionType] &&
            instructions[sourceConversionType]}
        </div>
      </>

      {children}

      {!loggedIn && !loading && (
        <Input
          required
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder={t("selectingFile.enterYourEmail")}
          customStyle={{ width: "100%", marginTop: 28 }}
        />
      )}

      {isError && <IconMessage message={t("selectingFile.enterValidEmail")} />}

      <Button
        type="submit"
        title={t("selectingFile.uploadNow")}
        customStyle={{
          margin: "24px auto 0",
          maxWidth: 185,
          alignSelf: "center",
        }}
      >
        {t("selectingFile.uploadNow")}
      </Button>

      {fileError && (
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 14,
          })}
        >
          <StyledIconWrapper width={22} height={22}>
            <Icon iconType="ERROR-ICON" />
          </StyledIconWrapper>

          <p
            className={css({
              marginLeft: 13,
              fontSize: 16,
              letterSpacing: 0.5,
              color: "#ff0000",
            })}
          >
            {fileError}
          </p>
        </div>
      )}
    </form>
  );
};
