import { InvokeCreator } from "xstate";
import { getConversionDetails } from "@/containers/Converter/common/helpers/getConversionDetails";
import { checkIfUserExists } from "@/services/usersService";
import { getFigmaFileId } from "@/utils/getFigmaFileId";
import { uploadFigmaFile } from "@/services/uploadFigmaService";
import { getGoogleSlidesId } from "@/utils/getGoogleSlidesFileId";
import { uploadGoogleSlides } from "@/services/uploadGoogleSlidesService";
import {
  fetchUploadStatus,
  IFileUploadResponse,
  uploadFile,
} from "@/services/uploadService";
import * as Sentry from "@sentry/node";
import { AxiosResponse } from "axios";
import { logger } from "@/utils/logger";
import { uploadCanvaFile } from "@/services/uploadCanvaService";
import { uploadFigJamFile } from "@/services/uploadFigJamService";
import { IContext, IEvent } from "./types";

function handleFileUpload(
  ctx: IContext,
  onUploadProgress: (event: ProgressEvent) => void,
): Promise<AxiosResponse<IFileUploadResponse>> {
  const { conversionType, userEmail: email } = ctx;
  if (!conversionType || !email) {
    throw Error(
      `Invalid state, ${
        !conversionType ? "conversionType" : "email"
      } should not be null`,
    );
  }

  const { conversionSource } = getConversionDetails(conversionType);
  const isFigmaConverter = conversionSource === "figma";
  const isGoogleSlidesConverter = conversionSource === "googleslides";
  const isCanvaConverter = conversionSource === "canva";
  const isFigJamConverter = conversionSource === "figjam";

  if (
    isFigmaConverter ||
    isGoogleSlidesConverter ||
    isCanvaConverter ||
    isFigJamConverter
  ) {
    const fileURL = ctx.fileURL || "";
    if (isFigmaConverter) {
      const file = getFigmaFileId(fileURL);
      return uploadFigmaFile(email, file || "", conversionType);
    }

    if (isFigJamConverter) {
      const file = getFigmaFileId(fileURL);
      return uploadFigJamFile(email, file || "", conversionType);
    }

    if (isGoogleSlidesConverter) {
      const file = getGoogleSlidesId(fileURL);
      return uploadGoogleSlides(email, file, conversionType);
    }

    if (isCanvaConverter) {
      return uploadCanvaFile(email, fileURL || "", conversionType);
    }
  }

  const file = ctx.file!;

  return uploadFile(email, file, conversionType, onUploadProgress);
}

export const uploadFileService: InvokeCreator<
  IContext,
  IEvent
> = ctx => send => {
  const email = ctx.userEmail;
  if (!email) {
    logger.error("ctx.userEmail should not be null");
    throw Error();
  }

  const handleUploadProgress = (event: ProgressEvent) => {
    send({
      type: "SET_UPLOAD_PROGRESS",
      uploadProgress: {
        loaded: event.loaded,
        total: event.total,
      },
    });
  };
  return checkIfUserExists(email)
    .then(userExists =>
      send({
        type: "SET_IS_NEW_USER",
        newUser: !userExists,
      }),
    )
    .then(() => handleFileUpload(ctx, handleUploadProgress))
    .then(res => {
      send({
        type: "SET_UPLOADED_FILE_DATA",
        file: res.data,
      });
    })
    .catch(error => {
      logger.error("Error happened on file upload", error);
      Sentry.withScope(scope => {
        scope.setExtras({ email });
        scope.setTag("where", "Services: handleUploadProgress");
        Sentry.captureException(error);
      });
      throw error;
    });
};

export const fetchUploadStatusService: InvokeCreator<
  IContext,
  any
> = ctx => send => {
  const fileName = ctx.uploadedFileName!;
  fetchUploadStatus(fileName)
    .then(status => {
      if (!status.isDone && !status.isFailed) {
        send({
          type: "RETRY_LATER",
        });
        return;
      }

      if (status.isDone) {
        send({
          type: "UPLOAD_FINISHED",
        });
      } else if (status.isFailed) {
        send({
          type: "UPLOAD_FAILED",
        });
      }
    })
    .catch(err => {
      throw err;
    });
};
