import { InvokeCreator } from "xstate";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import {
  convertFile,
  convertStatus,
  IConvertFileResponse,
} from "@/services/converterService";
import {
  fetchFileDetails,
  IResponse as IFileDetailsResponse,
} from "@/services/fileDetailsService";
import { isFreeConversion } from "@/utils/converter/isFreeConversion";
import { IContext, IEvent } from "./types";

const CONVERSION_POLLING_INTERVAL = 1000;

export const convertFileService: InvokeCreator<IContext, any> = (
  ctx,
): Promise<IConvertFileResponse> => {
  const { conversionType, uploadedFileName, hostedPageId } = ctx;
  const conversionDetails = getConversionDetails(
    conversionType || "XD_TO_SKETCH",
  );

  return convertFile({
    fileName: uploadedFileName || "",
    hostedPageId: hostedPageId || undefined,
    convertTo: conversionDetails.conversionTarget,
    free: isFreeConversion(conversionType),
  });
};

export const pollConversionStatusService: InvokeCreator<
  IContext,
  IEvent
> = ctx => send => {
  const { uploadedFileName } = ctx;

  const interval = setInterval(() => {
    convertStatus(uploadedFileName)
      .then(status => {
        send({
          type: "SET_CONVERSION_STATUS",
          status,
        });
      })
      .catch(err => {
        throw err;
      });
  }, CONVERSION_POLLING_INTERVAL);

  return () => {
    clearInterval(interval);
  };
};

export const fetchFileService: InvokeCreator<IContext, any> = (
  ctx,
): Promise<IFileDetailsResponse> => {
  return fetchFileDetails(ctx.uploadedFileName);
};
