import React from "react";
import { useFela } from "react-fela";

interface IUploadText {
  currentValue: string | number;
  totalValue: string | number;
}

export const UploadText: React.FC<IUploadText> = ({
  currentValue,
  totalValue,
}) => {
  const { css } = useFela();

  return (
    <p>
      <span className={css({ marginRight: 5 })}>{currentValue}</span>
      of
      <span className={css({ marginLeft: 5 })}>{totalValue}</span>
    </p>
  );
};
