import router from "next/router";
import { IConversionType } from "@/containers/Converter/types";

interface IParams {
  conversionType: IConversionType;
  newUser: boolean;
  fileName: string;
  userEmail?: string;
}

export function redirectToFilePage(params: IParams) {
  const { fileName, conversionType, userEmail, newUser } = params;

  let url = "/file";

  const escapedFileName = encodeURIComponent(fileName);
  url += `/${escapedFileName}?type=${conversionType}`;

  if (userEmail) {
    url += `&userEmail=${encodeURIComponent(userEmail)}`;
  }

  url += `&newUser=${newUser}`;

  router.push(url);
}
