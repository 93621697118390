import { getFigmaFileId } from "./getFigmaFileId";

const validUrls = ["figma.com/file", "figma.com/board"];

export const validateFigJamFileURL = (value: string) => {
  const isValidUrl = validUrls.some(url => value.toLowerCase().includes(url));
  const fileId = getFigmaFileId(value);

  return isValidUrl && !!fileId;
};
