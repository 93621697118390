import { Machine, sendParent } from "xstate";
import { IContext, IEvent, ISetUploadProgress } from "./types";
import { IEvent as IParentEvent } from "../types";
import { fetchUploadStatusService, uploadFileService } from "./services";
import {
  sendUploadResultToParent,
  setErrorType,
  setIsNewUser,
  setProcessingTimeoutError,
  setUploadedFileData,
} from "./actions";
import { initialContext } from "./context";

export const uploadMachine = Machine<IContext, any, IEvent>(
  {
    id: "fileUpload",
    initial: "uploading",
    context: initialContext,
    states: {
      uploading: {
        invoke: {
          src: uploadFileService,
          onDone: "fetchingUploadStatus",
          onError: "error",
        },
        on: {
          SET_UPLOADED_FILE_DATA: {
            actions: setUploadedFileData,
          },
          SET_IS_NEW_USER: {
            actions: setIsNewUser,
          },
          SET_UPLOAD_PROGRESS: {
            actions: sendParent<IContext, ISetUploadProgress, IParentEvent>(
              (_ctx, e) => ({
                type: "SET_UPLOAD_PROGRESS",
                uploadProgress: e.uploadProgress,
              }),
            ),
          },
        },
      },
      fetchingUploadStatus: {
        invoke: {
          src: fetchUploadStatusService,
          onError: "error",
        },
        on: {
          UPLOAD_FINISHED: "fileUploaded",
          RETRY_LATER: "repeatFetching",
          UPLOAD_FAILED: "error",
        },
        after: {
          PROCESSING_TIMEOUT: {
            actions: setProcessingTimeoutError,
            target: "error",
          },
        },
      },
      repeatFetching: {
        after: {
          PROCESSING_INTERVAL: "fetchingUploadStatus",
        },
      },
      fileUploaded: {
        type: "final",
        entry: sendUploadResultToParent,
      },
      error: {
        type: "final",
        entry: [setErrorType, sendUploadResultToParent],
      },
    },
  },
  {
    delays: {
      PROCESSING_INTERVAL: 2_000,
      PROCESSING_TIMEOUT: 9_999_999,
    },
  },
);
