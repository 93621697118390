import { IConverterContext } from "@/containers/Converter/types";
import { IContext } from "./types";

export const createInitialContext = ({
  conversionType,
  userEmail,
  loggedIn,
}: IConverterContext): IContext => ({
  conversionType,
  loggedIn,
  newUser: !loggedIn,
  userEmail: userEmail || null,
  file: null,
  fileURL: null,
  errorType: null,
  uploadProgress: null,
  uploadedFileName: null,
});
