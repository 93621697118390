import axios from "axios";
import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { IInvoice } from "@/typings/common";

export const checkIfUserExists = async (email: string): Promise<boolean> => {
  const result = await axios.get(BACKEND_ENDPOINTS.userExists, {
    params: { email },
    validateStatus: status => [200, 404].includes(status),
  });
  return result.status === 200;
};

export const getUserInvoices = async (): Promise<IInvoice[]> => {
  const result = await axios.get(BACKEND_ENDPOINTS.userInvoices);
  return result.data;
};

export const getInvoiceDownloadUrl = async (
  invoiceId: string,
): Promise<string> => {
  const result = await axios.get(
    BACKEND_ENDPOINTS.downloadInvoicePdf(invoiceId),
  );
  return result.data.downloadUrl;
};
