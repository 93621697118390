import { IHelpArticle } from "./types";

export const helpArticles: IHelpArticle[] = [
  {
    id: 0,
    href:
      "https://help.magicul.io/en/articles/4750813-how-to-embed-your-images-in-adobe-illustrator",
    title: "How to embed your images in Adobe Illustrator",
    text:
      "In this article we explain how you make sure that all images are included in your Illustrator file",
    displayForFormat: ["AI_TO_SKETCH", "AI_TO_FIGMA"],
  },
  {
    id: 1,
    href:
      "https://help.magicul.io/en/articles/5720456-why-are-shadows-and-icons-cut-off-in-my-converted-figma-file",
    title: "Why are shadows and icons cut off in my converted Figma file?",
    text:
      "After importing your converted Figma file you noticed that some shadows or borders are not being displayed. Here's why that is happening...",
    displayForFormat: [
      "XD_TO_FIGMA",
      "SKETCH_TO_FIGMA",
      "AI_TO_FIGMA",
      "PDF_TO_FIGMA",
      "PSD_TO_FIGMA",
      "GOOGLESLIDES_TO_FIGMA",
      "EPS_TO_FIGMA",
      "CANVA_TO_FIGMA",
      "INDD_TO_FIGMA",
      "POWERPOINT_TO_FIGMA",
    ],
  },
  {
    id: 2,
    href:
      "https://help.magicul.io/en/articles/5699297-component-instances-are-not-linked-anymore-here-s-why",
    title: "Component Instances are not linked anymore. Here's why.",
    text:
      "Find out why your Components might end up unlinked and turn into groups and what you can do about it.",
    displayForFormat: [
      "XD_TO_FIGMA",
      "SKETCH_TO_FIGMA",
      "AI_TO_FIGMA",
      "PDF_TO_FIGMA",
      "PSD_TO_FIGMA",
      "GOOGLESLIDES_TO_FIGMA",
      "EPS_TO_FIGMA",
      "CANVA_TO_FIGMA",
      "INDD_TO_FIGMA",
      "XD_TO_SKETCH",
      "FIGMA_TO_SKETCH",
      "AI_TO_SKETCH",
      "PDF_TO_SKETCH",
      "PSD_TO_SKETCH",
    ],
  },
  {
    id: 3,
    href:
      "https://help.magicul.io/en/articles/4629351-text-elements-are-vertically-offset-by-a-few-pixels",
    title: "Text elements are vertically offset by a few pixels",
    text:
      "After converting your file some text elements are vertically not correctly positioned? Read here how to resolve this issue.",
    displayForFormat: [
      "XD_TO_FIGMA",
      "SKETCH_TO_FIGMA",
      "AI_TO_FIGMA",
      "PDF_TO_FIGMA",
      "PSD_TO_FIGMA",
      "GOOGLESLIDES_TO_FIGMA",
      "EPS_TO_FIGMA",
      "CANVA_TO_FIGMA",
      "INDD_TO_FIGMA",
      "XD_TO_SKETCH",
      "FIGMA_TO_SKETCH",
      "AI_TO_SKETCH",
      "PDF_TO_SKETCH",
      "PSD_TO_SKETCH",
      "POWERPOINT_TO_FIGMA",
      "POWERPOINT_TO_SKETCH",
      "POWERPOINT_TO_XD",
    ],
  },
  {
    id: 4,
    href:
      "https://help.magicul.io/en/articles/4750993-elements-with-3d-rotation-aren-t-being-properly-converted",
    title: "Elements with 3D rotation aren't being properly converted",
    text:
      "Your Adobe XD file contained elements that were 3D rotated and they didn't convert as expected?",
    displayForFormat: ["XD_TO_SKETCH", "XD_TO_FIGMA", "XD_TO_AI", "XD_TO_PSD"],
  },
  {
    id: 5,
    href:
      "https://help.magicul.io/en/articles/5744941-adobe-xd-file-is-taking-longer-to-open",
    title: "Adobe XD file is taking longer to open",
    text:
      "Why Adobe XD may get stuck opening your file and what you can do about it",
    displayForFormat: [
      "FIGMA_TO_XD",
      "SKETCH_TO_XD",
      "PDF_TO_XD",
      "SKETCH_TO_XD",
      "PDF_TO_XD",
    ],
  },
  {
    id: 6,
    href:
      "https://help.magicul.io/en/articles/5139500-missing-text-elements-after-a-photoshop-conversion-update-all-text-layers",
    title:
      "Missing Text Elements After a Photoshop Conversion -- Update all Text Layers",
    text:
      "A quick guide to help restore missing text elements to designs after converting Figma, XD and Sketch files to Photoshop with Magicul.",
    displayForFormat: [
      "XD_TO_PSD",
      "FIGMA_TO_PSD",
      "SKETCH_TO_PSD",
      "PDF_TO_PSD",
    ],
  },
];
