import { Action, assign, sendParent } from "xstate";

import { IConversionType } from "@/containers/Converter/types";
import { getConversionDetails } from "@/containers/Converter/common/helpers/getConversionDetails";
import { IEvent as IParentEvent } from "@/containers/Converter/UploadFile/state/types";
import { storeConvertedFile } from "@/services/convertedFilesService";
import {
  IContext,
  ISetIsNewUser,
  ISetUploadedFileData,
  ISetUploadMachineResult,
  ISetUploadProgress,
} from "./types";

export const setUploadProgress = assign<IContext, ISetUploadProgress>({
  uploadProgress: (_ctx, event) => event.uploadProgress,
});

export const setIsNewUser = assign<IContext, ISetIsNewUser>({
  newUser: (_ctx, event) => event.newUser,
});

export const setUploadedFileData = assign<IContext, ISetUploadedFileData>({
  uploadedFileName: (ctx, event) => {
    const { fileName } = event.file;

    storeConvertedFile({
      fileName,
      conversionType: ctx.conversionType as IConversionType,
      convertStatus: "Uploaded",
    });

    return fileName;
  },
  numberOfArtboards: (_ctx, event) => {
    return event.file.artboardsCount;
  },
});

export const sendUploadResultToParent: Action<IContext, any> = sendParent<
  IContext,
  ISetUploadMachineResult,
  IParentEvent
>(ctx => ({
  type: "SET_UPLOAD_MACHINE_RESULT",
  errorType: ctx.errorType,
  uploadedFileName: ctx.uploadedFileName,
  isNewUser: ctx.newUser,
}));

export const setErrorType = assign<IContext, any>({
  errorType: ctx => {
    if (ctx.errorType) return ctx.errorType; // skip if errorType was already set

    const { conversionSource } = getConversionDetails(
      ctx.conversionType || "XD_TO_SKETCH",
    );
    if (conversionSource === "figma") {
      return "FILE_NOT_PUBLIC_ERROR";
    }
    return "UPLOADING_ERROR";
  },
});

export const setProcessingTimeoutError = assign<IContext, any>({
  errorType: "PROCESSING_TIMEOUT_ERROR",
});
