import { assign } from "xstate";
import {
  getConversionDetails,
  mergeConversionType,
} from "@/containers/Converter/common/helpers";
import {
  IChangeConversionTarget,
  ICompleteCheckout,
  IContext,
  IFileDetailsResult,
  ISelectPaymentType,
  IUpdateUser,
} from "./types";

export const setFileFetchingError = assign<IContext, any>({
  errorType: _ctx => {
    if (_ctx?.uploadedFileName?.includes(".fig")) {
      return "FILE_NOT_PUBLIC_ERROR";
    }
    return "FILE_FETCHING_ERROR";
  },
});

export const setPaymentType = assign<IContext, ISelectPaymentType>({
  paymentType: (_ctx, event) => event.paymentType,
});

export const setFileDetails = assign<IContext, IFileDetailsResult>({
  fileDetails: (_ctx, event) => event.data,
});

export const setHostedPageId = assign<IContext, ICompleteCheckout>({
  hostedPageId: (_ctx, event) => event.hostedPageId,
});

export const setIsNewUser = assign<IContext, ICompleteCheckout>({
  user: (ctx, event) => ({ ...ctx.user, isNew: event.isNewUser }),
});

export const setUserData = assign<IContext, IUpdateUser>({
  user: (_ctx, event) => event.user,
});

export const setConversionType = assign<IContext, IChangeConversionTarget>({
  conversionType: (ctx, event) => {
    const { conversionSource } = getConversionDetails(ctx.conversionType);
    return mergeConversionType(conversionSource, event.conversionTarget);
  },
});
