import React from "react";
import { SelectingFilePlaceholderProps } from "@/containers/Converter/UploadFile/components/SelectingFile/SelectingFilePlaceholder/types";
import { SelectingFile } from "..";

export const SelectingFilePlaceholder: React.FC<SelectingFilePlaceholderProps> = ({
  formats,
}) => (
  <SelectingFile
    conversionType="XD_TO_SKETCH"
    formats={formats}
    disabled={true}
    onConversionTypeChange={() => undefined}
    onFileSelected={() => undefined}
    onFileUrlChange={() => undefined}
    onFileUrlSubmit={() => undefined}
  />
);
