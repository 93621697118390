import React from "react";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";

import { Modal } from "@/components/Modal";
import { Icon } from "@/components/Icon";
import { Heading } from "@/components/Heading";
import { useTheme } from "@/hooks/useTheme";
import { Button } from "@/components/Button";

interface IProps {
  onSubmit: () => void;
  onReject: () => void;
}

export const ConfirmCreditDeductionModal: React.FC<IProps> = ({
  onSubmit,
  onReject,
}) => {
  const { css } = useFela();
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Modal
      id="conversion-confirm-modal"
      onClose={onReject}
      customStyle={{
        maxWidth: 436,
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Icon iconType="SHIELD-ALERT" />
        <Heading
          customStyle={{
            maxWidth: "85%",
            margin: "32px auto 16px",
            textAlign: "center",
            color: colors.CHARCOAL,
          }}
          as="h3"
        >
          <Trans
            i18nKey="converter:conversionConfirmModal.title"
            components={[
              <span key="0" className={css({ color: colors.PURLPE })} />,
            ]}
          />
        </Heading>
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            margin: "32px 0 10px",
          })}
        >
          <Button
            variant="OUTLINED"
            onClick={onReject}
            customStyle={{ width: 136 }}
          >
            {t("common:actions.cancel")}
          </Button>

          <Button
            customStyle={{ width: 136, marginLeft: 25 }}
            title="CONFIRM"
            variant="PRIMARY"
            onClick={onSubmit}
          >
            {t("common:actions.confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
