/**
 * This file would contain incapsulated logic only for 'my files' page.
 * It should use bare minimum imports from existing modules for now to avoid difficulties
 * in future refactoring (I point at you, converter) and support.
 */
import Axios from "axios";

import { LOCAL_STORAGE_KEYS } from "@/constants/localStorage";
import { BACKEND_ENDPOINTS } from "@/constants/backend";

import {
  IStoredFileDetails,
  IFileRecord,
  IRawFileRecord,
} from "@/typings/file";
import { hasSourceFile } from "@/utils/converter/hasSourceFile";
import { getFileDetails } from "@/utils/converter/getFileDetails";
import { logger } from "@/utils/logger";

/**
 * Gets converted files data from `localStorage`.
 */
export const getStoredConvertedFiles = (): IStoredFileDetails[] => {
  if (typeof window === "undefined") {
    return [];
  }
  const fileDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.fileDetails);

  if (!fileDetails) {
    return [];
  }

  try {
    const parsedFileDetails = JSON.parse(fileDetails);
    return parsedFileDetails;
  } catch (error) {
    logger.error(
      "Couldn't parse stored files from localStorage. Record is probably broken.",
      error,
    );

    return [];
  }
};

/**
 * Stores single file data in `localStorage`
 */
export const storeConvertedFile = (fileToStore: IStoredFileDetails) => {
  const existingFiles = getStoredConvertedFiles();
  const filteredFiles = existingFiles.filter(
    (file: IStoredFileDetails) => file.fileName !== fileToStore.fileName,
  );

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.fileDetails,
    JSON.stringify([...filteredFiles, fileToStore]),
  );
};

/**
 * Loads converted files of current user and transforms them for future usage
 */
export const getConvertedFilesOfUser = async (): Promise<IFileRecord[]> => {
  let loadedFiles: IFileRecord[];

  try {
    const { data: filesRecord } = await Axios.get<
      Record<string, IRawFileRecord>
    >(BACKEND_ENDPOINTS.convertedFilesOfUser);

    loadedFiles = Object.keys(filesRecord)
      .filter(fileName => Boolean(filesRecord[fileName]))
      .filter(fileName => hasSourceFile(filesRecord[fileName]))
      .map(fileName => ({
        ...filesRecord[fileName],
        fileName,
        files: getFileDetails(filesRecord[fileName]),
      }));
  } catch (error) {
    logger.error("Couldn't load or parse user's files.", error);
    loadedFiles = [];
  }

  return loadedFiles;
};

/**
 * Loads converted files by name and transforms them for future usage
 */
export const getStoredConvertedFilesInfo = async (
  fileNames: string[],
): Promise<IFileRecord[]> => {
  let loadedFiles: IFileRecord[];

  try {
    const query = fileNames
      .map(fn => `fileName=${encodeURIComponent(fn)}`)
      .join("&");
    const { data: filesRecord } = await Axios.get<
      Record<string, IRawFileRecord>
    >(`${BACKEND_ENDPOINTS.multipleFileDetails}?${query}`);

    loadedFiles = Object.keys(filesRecord)
      .filter(fileName => Boolean(filesRecord[fileName]))
      .filter(fileName => hasSourceFile(filesRecord[fileName]))
      .map(fileName => {
        return {
          ...filesRecord[fileName],
          fileName,
          files: getFileDetails(filesRecord[fileName]),
        };
      });
  } catch (error) {
    logger.error("Couldn't load or parse data of stored files.", error);
    loadedFiles = [];
  }

  return loadedFiles;
};

const removeLocalConvertedFiles = (fileNames: string[]) => {
  const existingFiles = getStoredConvertedFiles();
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.fileDetails,
    JSON.stringify(
      existingFiles.filter(
        existingFile => !fileNames.includes(existingFile.fileName),
      ),
    ),
  );
};

/**
 * Remove files by name
 */
export const removeConvertedFiles = (fileNames: string[]) => {
  return Axios.delete(BACKEND_ENDPOINTS.delete, {
    data: { fileNames },
  })
    .then(() => {
      removeLocalConvertedFiles(fileNames);
    })
    .catch(error => {
      logger.error(
        "Couldn't delete files.",
        error.response?.data?.message || error.message,
      );

      if (error.response.status === 404) {
        removeLocalConvertedFiles(fileNames);
      }
    });
};
