import { landingPagesMap } from "@/containers/Converter/maps";
import { IConversionType } from "@/containers/Converter/types";

export const getConversionTypeOrFail = (pathname: string): IConversionType => {
  const conversionType = getConversionTypeFromPathname(pathname);
  if (!conversionType) {
    throw Error(
      `Failed to parse conversion type from the pathname: ${pathname}`,
    );
  }
  return conversionType;
};

export const getConversionTypeFromPathname = (
  pathname: string,
): IConversionType | null => {
  const keysArr = Object.keys(landingPagesMap) as IConversionType[];
  return (
    (keysArr.find(
      key => landingPagesMap[key] === pathname,
    ) as IConversionType) || null
  );
};
