import React from "react";
import { useMachine } from "@xstate/react";
import { useRouter } from "next/router";

import { createUploadFileMachine } from "@/containers/Converter/UploadFile/state/machine";
import { IConversionType } from "@/containers/Converter/types";
import { landingPagesMap } from "@/containers/Converter/maps";
import {
  SelectingFile,
  Uploading,
  BetaSignUp,
  FileNotPublicError,
  TrialUsed,
} from "@/containers/Converter/UploadFile/components";
import { FigjamError } from "@/containers/Converter/UploadFile/components/FigjamError";
import { UploadError } from "@/containers/Converter/UploadFile/components/UploadError";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { ConverterDroppedFile } from "@/containers/Converter/UploadFile/components/ConverterDroppedFile";
import { UploadFileProps } from "@/containers/Converter/UploadFile/types";
import {
  FIGMA_REDIRECT_URI_CONVERTER,
  getConnectFigmaAccountUrl,
  encodeFigmaCallbackState,
} from "@/services/figmaService";
import { IFigmaCallbackState } from "@/typings/common";
import { addSearchParamsToUrlPath } from "@/services/urlService";
import { QUERY_PARAMS_KEYS } from "@/constants/queryParams";
import { XdError } from "./components/XdError";

export const UploadFile: React.FC<UploadFileProps> = ({ converterCtx }) => {
  const uploadFileMachine = createUploadFileMachine(converterCtx);
  const [current, send] = useMachine(uploadFileMachine);
  const router = useRouter();
  // TODO: see if there is a better way to forward figma callback related data
  const [figmaCallbackEmail, setFigmaCallbackEmail] = React.useState("");
  const [figmaCallbackFileURL, setFigmaCallbackFileURL] = React.useState("");

  const {
    context: ctx,
    context: { conversionType, file, fileURL },
  } = current;

  const showFileSelection = current.matches("selectingFile");
  const showDroppedFile = current.matches("droppedFile");
  const showUploading = current.matches("uploading");
  const showBetaSignUp = current.matches("betaSignUp");
  const showTrialUsed = current.matches("trialUsed");

  const showError = current.matches("error");

  const onConversionTypeChange = (newType: IConversionType) => {
    if (!converterCtx.inheritConversionTypeFromRoute) {
      send({ type: "SET_CONVERSION_TYPE", conversionType: newType });
      return;
    }

    // skip redirect if file was selected and target format changes
    if (file || fileURL) {
      const {
        conversionSource: selectedSourceFormat,
        conversionTarget: selectedTargetFormat,
      } = getConversionDetails(conversionType);
      const {
        conversionSource: newSourceFormat,
        conversionTarget: newTargetFormat,
      } = getConversionDetails(newType);

      if (
        selectedSourceFormat === newSourceFormat &&
        selectedTargetFormat !== newTargetFormat
      ) {
        send({ type: "SET_CONVERSION_TYPE", conversionType: newType });
        return;
      }
    }

    router.push(landingPagesMap[newType]);
  };

  return (
    <>
      {showFileSelection && (
        <SelectingFile
          conversionType={conversionType}
          formats={converterCtx.formats}
          fileURL={ctx.fileURL ?? undefined}
          onConversionTypeChange={onConversionTypeChange}
          onFileSelected={file => {
            send({ type: "SELECT_FILE", file });
          }}
          onFileUrlChange={fileURL => {
            send({ type: "CHANGE_FILE_URL", fileURL });
          }}
          onFileUrlSubmit={(fileURL, userEmail) => {
            // TODO: see if there is a better way to forward figma callback related data
            setFigmaCallbackEmail(userEmail);
            setFigmaCallbackFileURL(fileURL);
            send({ type: "SUBMIT_FILE_URL", fileURL, userEmail });
          }}
        />
      )}

      {showDroppedFile && (
        <ConverterDroppedFile
          file={ctx.file!}
          conversionType={conversionType}
          formats={converterCtx.formats}
          onConversionTypeChange={onConversionTypeChange}
          updateConversionType={(conversionType: IConversionType) =>
            send({ type: "SET_CONVERSION_TYPE", conversionType })
          }
          onFileSubmit={userEmail => {
            send({ type: "SUBMIT_FILE_FORM", userEmail });
          }}
          onFileReset={() => {
            send({ type: "RESET_FILE" });
          }}
        />
      )}

      {showUploading && (
        <Uploading
          conversionType={conversionType}
          uploadProgress={ctx.uploadProgress || undefined}
        />
      )}

      {showBetaSignUp && (
        <BetaSignUp
          conversionType={conversionType}
          formats={converterCtx.formats}
          onConversionTypeChange={onConversionTypeChange}
        />
      )}

      {showTrialUsed && <TrialUsed />}

      {showError && (
        <>
          {ctx.errorType === "UPLOADING_ERROR" && (
            <UploadError
              onRetry={() => {
                send({ type: "RETRY_UPLOAD" });
              }}
            />
          )}
          {ctx.errorType === "FILE_NOT_PUBLIC_ERROR" && (
            <FileNotPublicError
              onRetry={router.reload}
              onFigmaConnect={() => {
                const state: IFigmaCallbackState = {
                  email: figmaCallbackEmail,
                  redirectUrl: addSearchParamsToUrlPath(router.asPath, {
                    [QUERY_PARAMS_KEYS.figmaCallbackEmail]: figmaCallbackEmail,
                    [QUERY_PARAMS_KEYS.figmaCallbackFileURL]: figmaCallbackFileURL,
                  }),
                };
                router.push(
                  getConnectFigmaAccountUrl(
                    FIGMA_REDIRECT_URI_CONVERTER,
                    encodeFigmaCallbackState(state),
                  ),
                );
              }}
            />
          )}
          {ctx.errorType === "FIGJAM_FILE_ERROR" && (
            <FigjamError onRetry={() => send({ type: "RETRY_UPLOAD" })} />
          )}
          {ctx.errorType === "XD_CLOUD_FILE_ERROR" && (
            <XdError onRetry={router.reload} />
          )}
        </>
      )}
    </>
  );
};
