export const PLANS_WITH_FREE_CREDITS: PaymentPlanType[] = [
  "Lifetime",
  "Annual",
  "Enterprise",
];

export type PaymentPlanType =
  | "PayAsYouGo"
  | "Credits"
  | "Lifetime"
  | "Annual"
  | "Enterprise";

export interface IUserData {
  id: string;
  email: string;
  firstname: string | null;
  lastname: string | null;
  emailVerified: boolean;
  filesCount: number;
}

export interface ISubscriptionData {
  usedCredits: number;
  totalCredits: number;
  latestPlanType: PaymentPlanType;
  backupFileLimit: number | null;
  backupFileCount: number | null;
  latestBackupPlanType: "backup-basic" | "backup-pro" | "backup-enterprise";
}

export type IUser = IUserData & ISubscriptionData;

export interface IAuth {
  loading: boolean;
  loggedIn: boolean;
  user: IUser | null;

  setAuthState: React.Dispatch<React.SetStateAction<IAuth>>;

  login: (shouldRedirect?: boolean) => Promise<void>;
  logout: () => void;
  refreshAccount: () => void;
}
